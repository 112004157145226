import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoadingModal from "../dialogs/LoadingModal";
import { Alert, Collapse } from "reactstrap";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class Login extends Component {
  session = new SessionHelper();

  constructor(props) {
    super(props);

    if (
      this.session.get("userGroup") == "1" &&
      this.session.get("authID") != "null"
    ) {
      this.state = {
        showLoginPage: false
      };
      window.location.href = "/super-admin";
    } else if (
      this.session.get("isLoggedIn") == "true" &&
      this.session.get("authID") != "null"
    ) {
      this.state = {
        showLoginPage: false
      };
      window.location.href = "/home";
    } else {
      this.state = {
        errorStatus: null,
        showError: false,
        errorMessage: null,
        isloading: false,
        showLoginPage: true
      };
    }

    this.textInput = React.createRef();
    this.focus = this.focus.bind(this);

    this.attemptLogin = this.attemptLogin.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  focus() {
    if (this.textInput.current !== null) this.textInput.current.focus();
  }

  onChangeHandler() {
    this.setState({
      errorStatus: null,
      showError: false,
      errorMessage: null
    });
  }

  async attemptLogin() {
    this.setState({
      isloading: true,
      errorStatus: null,
      showError: false,
      errorMessage: null
    });

    let userid = document.getElementById("inputEmail").value;
    let pwd = document.getElementById("inputPassword").value;
    if (!userid) {
      this.setState({
        showError: true,
        errorStatus: 201,
        errorMessage: "Please enter your email address"
      });
    } else if (!pwd) {
      this.setState({
        showError: true,
        errorStatus: 202,
        errorMessage: "Please enter your account password"
      });
    } else {
      const url = this.props.baseURL + "login/user";

      let parameters = {
        userID: userid,
        userPassword: pwd
      };

      let apiHelper = new RestAPIHelper();
      const apiResponse = await apiHelper.post(url, parameters);

      if (
        apiResponse["status"] == 200 &&
        apiResponse["data"]["user group"] == "3"
      ) {
        this.setState({
          showError: true,
          errorStatus: 203,
          errorMessage: "You can't login here"
        });
      } else if (
        apiResponse["status"] == 200 &&
        apiResponse["data"]["user group"] == "1"
      ) {
        this.session.set("authID", apiResponse["data"]["authentication token"]);
        this.session.set("userGroup", apiResponse["data"]["user group"]);
        this.session.set("userID", userid);
        this.session.set("isLoggedIn", true);
        window.location.href = "/super-admin";
      } else if (apiResponse["status"] == 200) {
        this.session.set("authID", apiResponse["data"]["authentication token"]);
        this.session.set("userGroup", apiResponse["data"]["user group"]);
        this.session.set("userID", userid);
        this.session.set("isLoggedIn", true);
        window.location.href = "/home";
      } else if (apiResponse["error"]) {
        this.setState({
          showError: true,
          errorStatus: 203,
          errorMessage: apiResponse["message"],
          isloading: false
        });
      } else {
        this.setState({
          showError: true,
          errorStatus: apiResponse["status"],
          errorMessage: apiResponse["message"]
        });
      }
    }
    this.setState({ isloading: false });
    this.focus();
  }

  render() {
    if (this.state.showLoginPage === false) return null;
    return (
      <div className="container">
        <div align="center" className="col-md-12 col-sm-12">
          <img
            style={{ maxHeight: "100px", marginTop: "5%" }}
            src="img/logo.svg"
          />
        </div>
        <div className="card card-login mx-auto mt-5">
          <div id="res1001" className="card-header">
            <font size="4">
              <center>HR Pro - SignIn</center>
            </font>
          </div>
          <Collapse
            isOpen={
              this.state.errorMessage &&
              this.state.errorStatus !== null &&
              this.state.errorStatus !== 201 &&
              this.state.errorStatus !== 202
            }
          >
            <Alert color="danger" className="rounded-0 mb-0">
              {this.state.errorMessage}
            </Alert>
          </Collapse>
          <div className="card-body">
            <form>
              <div className="form-group">
                <div className="form-label-group">
                  <input
                    ref={
                      this.state.errorStatus === 201 && this.state.showError
                        ? this.textInput
                        : null
                    }
                    type="email"
                    id="inputEmail"
                    onChange={this.onChangeHandler}
                    className={
                      this.state.errorStatus === 201 && this.state.showError
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Email address"
                    required="required"
                    autoFocus="autofocus"
                  />
                  <div className="invalid-feedback">
                    {this.state.errorMessage}
                  </div>
                  <label htmlFor="inputEmail" style={{ cursor: "text" }}>
                    Email address
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <input
                    type="password"
                    ref={
                      this.state.errorStatus === 202 && this.state.showError
                        ? this.textInput
                        : null
                    }
                    onChange={this.onChangeHandler}
                    id="inputPassword"
                    className={
                      this.state.errorStatus === 202 && this.state.showError
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Password"
                    required="required"
                  />
                  <div className="invalid-feedback">
                    {this.state.errorMessage}
                  </div>
                  <label htmlFor="inputPassword" style={{ cursor: "text" }}>
                    Password
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      value="remember-me"
                      className="mr-1"
                    />
                    Remember Me
                  </label>
                </div>
              </div>
              <Link
                to="#"
                onClick={this.attemptLogin}
                className="btn btn-primary btn-block"
                style={{ color: "white" }}
              >
                Login
              </Link>
            </form>
          </div>
          <hr className="mx-4 mt-0" />
          <div className="text-center mb-3">
            <a className="d-block small" href="/signup">
              Don't have an account yet? Signup now!
            </a>
            <a className="d-block small" href="/forgot-password">
              Forgot Password?
            </a>

            <a className="d-block small" href="/login/oauth">
              OAuth Login
            </a>
          </div>
        </div>

        <LoadingModal
          show={this.state.isloading}
          message="Authenticating Credentials. Please Wait..."
        />
      </div>
    );
  }
}

export default Login;
