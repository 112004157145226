import React, { Component } from "react";

class OAuthLogin extends Component {
  render() {
    const formUrl = this.props.baseURL + "oauth2/authorize";
    return (
      <form action={formUrl} method="post">
        <input type="text" name="client_id" value="testclient" hidden />
        <input type="text" name="client_secret" value="testpass" hidden />
        <input type="text" name="response_type" value="code" hidden />
        <input
          type="text"
          name="redirect_uri"
          value="https://ats.truhire.in/login/oauth/response"
          hidden
        />
        <input type="text" name="state" value="abc" hidden />
        <input type="text" name="scope" value="userinfo" hidden />
        <input type="submit" id="formSubmit" value="submit" hidden />
      </form>
    );
  }

  async componentDidMount() {
    // // const url = "https://dev.v-empower.com:81/API-PHP/oauth2/authorize";
    // const url = "http://192.168.0.52/API-PHP/oauth2/authorize";
    // const parameters = {
    //   client_id: "testclient",
    //   client_secret: "testpass",
    //   response_type: "code",
    //   // redirect_uri: "https://dev.v-empower.com:81/API-PHP",
    //   redirect_uri: "http://192.168.0.52:3000/login/oauth/response",
    //   state: "abc",
    //   scope: "userinfo"
    // };

    // let apihelper = new RestAPIHelper();
    // const apiResponse = await apihelper.post(url, parameters);
    // console.log(apiResponse);
    // if (apiResponse) {
    //   window.location.href = apiResponse["data"]["url"];
    // }

    document.getElementById("formSubmit").click();
  }
}

export default OAuthLogin;
