import React, { Component } from "react";
import { Link } from "react-router-dom";

class CompanyProfile extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-header">
          <h4 className="page-title">Company Profile</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/home/dashboard" className="text-light-color">
                Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Profile
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-lg-12 col-xl-4 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="text-center">
                  <div className="userprofile social">
                    <div className="userpic">
                      <img
                        src="/assets/img/avatar/company.jpeg"
                        alt=""
                        className="userpicimg"
                      />
                    </div>
                    <h3 className="username">TCS Technologies</h3>
                    <p>IT Consultants, India</p>
                    <div className="text-center mb-2">
                      <span>
                        <i className="fa fa-star text-warning" />
                      </span>
                      &nbsp;
                      <span>
                        <i className="fa fa-star text-warning" />
                      </span>
                      &nbsp;
                      <span>
                        <i className="fa fa-star text-warning" />
                      </span>
                      &nbsp;
                      <span>
                        <i className="fa fa-star-half-o text-warning" />
                      </span>
                      &nbsp;
                      <span>
                        <i className="fa fa-star-o text-warning" />
                      </span>
                    </div>
                    <div className="socials text-center">
                      <a href="" className="btn btn-circle btn-primary ">
                        <i className="fa fa-facebook" />
                      </a>
                      &nbsp;
                      <a href="" className="btn btn-circle btn-danger ">
                        <i className="fa fa-google-plus" />
                      </a>
                      &nbsp;
                      <a href="" className="btn btn-circle btn-info ">
                        <i className="fa fa-twitter" />
                      </a>
                      &nbsp;
                      <a href="" className="btn btn-circle btn-warning ">
                        <i className="fa fa-envelope" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-8 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-body p-4">
                <div className="row d-flex">
                  <div className="col-lg-6 col-md-12">
                    <ul
                      className="nav nav-pills countlist mb-0 "
                      role="tablist"
                    >
                      <li>
                        <h4>
                          7238
                          <br />
                          <small className="text-muted">Followers</small>
                        </h4>
                      </li>
                      <li>
                        <h4>
                          281
                          <br />
                          <small className="text-muted">Following</small>
                        </h4>
                      </li>
                      <li>
                        <h4>
                          9043
                          <br />
                          <small className="text-muted">Activity</small>
                        </h4>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-md-12 follower">
                    <div className="float-right d-none d-lg-block">
                      <a href="#" className="btn btn-primary mt-1">
                        <i className="fe fe-users followbtn ml-1" /> Follow
                      </a>
                      &nbsp;
                      <Link
                        to="/home/company/editprofile"
                        className="btn btn-success mt-1"
                      >
                        <i className="fe fe-edit followbtn ml-1" /> Edit Profile
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body border-top p-4">
                <h5 className="media-heading mt-1">About</h5>
                <p>
                  simply dummy text of the printing and typesetting industry.
                  Lorem Ipsum has been the industry's standard dummy when an
                  unknown printer took a galley of type and scrambled it to make
                  a type specimen book. It has survived not only five centuries
                  nchanged.
                </p>
                <p className="mb-0">
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row profile-card">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h4>Personal Details</h4>
              </div>
              <div className="card-body">
                <p>
                  <b>User ID :</b> TCS12378
                </p>
                <p>
                  <b>Name :</b> Alica Nestle
                </p>
                <p>
                  <b>Emial :</b> jessicalee@tcs.com
                </p>
                <p>
                  <b>Phone :</b> +91 040 6789234{" "}
                </p>
                <p>
                  <b>Address : </b> Deccan Park, Plot No.1, Software Units
                  Layout, Madhapur, Hyderabad, Telangana 500081
                </p>
                <p className="mb-0">
                  <b>Story : </b> There are many variations of passages of Lorem
                  Ipsum available, but the majority have suffered alteration in
                  some form, by injected humour, or randomised words which don't
                  look even slightly believable.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CompanyProfile;
