import React, { Component } from "react";
import { Link } from "react-router-dom";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

export default class Home extends Component {
  session = new SessionHelper();
  settingsLink = 0;

  constructor(props) {
    super(props);
    this.state = { userInfo: null };
  }

  wordCapitalize(word) {
    let res = "";
    const len = word.length;
    let doCap = true;
    for (let i = 0; i < len; i++) {
      if (word.charAt(i) == " ") {
        doCap = true;
        res += word.charAt(i);
      } else if (word.charAt(i) == "_") {
        doCap = true;
        res += " ";
      } else {
        if (doCap) {
          res += word.charAt(i).toUpperCase();
          doCap = false;
        } else {
          res += word.charAt(i).toLowerCase();
        }
      }
    }
    return res;
  }

  async componentDidMount() {
    const userProfileDetails =
      this.props.baseURL +
      "user/profile?authenticationToken=" +
      this.session.get("authID");

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.get(userProfileDetails);
    if (apiResponse["status"] == "200") {
      this.setState({
        dataLoaded: true,
        userInfo: apiResponse["data"]["user"]
      });
    } else {
      console.log("Error : " + apiResponse["message"]);
    }

    const script = document.createElement("script");
    script.src = "/assets/plugins/toggle-menu/sidemenu.js";
    script.id = "sidemenu";
    document.body.replaceChild(script, document.getElementById(script.id));
  }

  home() {
    if (
      this.session.get("isLoggedIn") == "true" &&
      this.session.get("userGroup") == "1"
    ) {
      window.location.href = "/super-admin";
    } else if (this.session.get("isLoggedIn") == "true") {
      window.location.href = "/home";
    } else {
      window.location.href = "/login";
    }
  }

  render() {
    let photoURL =
      this.props.baseURL +
      "user/photo?authenticationToken=" +
      this.session.get("authID");
    return (
      <nav className="navbar navbar-expand-lg main-navbar">
        <Link className="header-brand" to="/home">
          <img
            src="/assets/img/brand/logo-white.png"
            className="header-brand-img"
            alt="TruHire logo"
          />
        </Link>
        <form className="form-inline mr-auto">
          <ul className="navbar-nav mr-2">
            <li>
              <a
                href="#"
                data-toggle="sidebar"
                className="nav-link nav-link toggle"
              >
                <i className="fa fa-reorder" />
              </a>
            </li>
            <li>
              <a
                href="#"
                data-toggle="search"
                className="nav-link nav-link d-md-none navsearch"
              >
                <i className="fa fa-search" />
              </a>
            </li>
          </ul>
          <div className="search-element mr-3">
            <input
              className="form-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <span className="Search-icon">
              <i className="fa fa-search" />
            </span>
          </div>
        </form>
        <ul className="navbar-nav navbar-right">
          <li className="dropdown dropdown-list-toggle d-none d-lg-block my-auto">
            <a
              href="#"
              data-toggle="dropdown"
              className="nav-link  nav-link-lg"
            >
              {this.session.get("userGroup") != "1" &&
                this.state.userInfo &&
                "Credits: " + this.state.userInfo["CREDITS"]}
            </a>
          </li>
          <li className="dropdown dropdown-list-toggle d-none d-lg-block my-auto">
            <a
              className="btn btn-sm btn-warning m-b-5 m-t-5"
              href="#"
              data-toggle="dropdown"
            >
              Buy Credits
            </a>
          </li>
          <li className="dropdown dropdown-list-toggle ">
            <a
              href="#"
              data-toggle="dropdown"
              className="nav-link nav-link-lg pr-0"
            >
              <span className="badge badge-danger nav-link-badge">5</span>
              <i className="hvr-buzz fa fa-envelope-o" />
            </a>
            <div className="dropdown-menu dropdown-list dropdown-menu-right">
              <div className="dropdown-header">
                Messages
                <div className="float-right">
                  <a href="#" className="text-white">
                    View All
                  </a>
                </div>
              </div>
              <div className="dropdown-list-content">
                <a href="#" className="dropdown-item dropdown-item-unread">
                  <img
                    alt="image"
                    src="assets/img/avatar/avatar-3.jpeg"
                    className="rounded-circle dropdown-item-img"
                  />
                  <div className="dropdown-item-desc">
                    <div className="dropdownmsg d-flex">
                      <div className="">
                        <b>Stewart Ball</b>
                        <p>Your template awesome</p>
                      </div>
                      <div className="time">6 hours ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" className="dropdown-item dropdown-item-unread">
                  <img
                    alt="image"
                    src="assets/img/avatar/avatar-2.jpeg"
                    className="rounded-circle dropdown-item-img"
                  />
                  <div className="dropdown-item-desc">
                    <div className="dropdownmsg d-flex">
                      <div className="">
                        <b>Jonathan North</b>
                        <p>Your Order Shipped.....</p>
                      </div>
                      <div className="time">45 mins ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" className="dropdown-item">
                  <img
                    alt="image"
                    src="assets/img/avatar/avatar-4.jpeg"
                    className="rounded-circle dropdown-item-img"
                  />
                  <div className="dropdown-item-desc">
                    <div className="dropdownmsg d-flex">
                      <div className="">
                        <b>Victor Taylor</b>
                        <p>Hi!, I' am web developer</p>
                      </div>
                      <div className="time"> 8 hours ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" className="dropdown-item">
                  <img
                    alt="image"
                    src="assets/img/avatar/avatar-3.jpeg"
                    className="rounded-circle dropdown-item-img"
                  />
                  <div className="dropdown-item-desc">
                    <div className="dropdownmsg d-flex">
                      <div className="">
                        <b>Ruth Arnold</b>
                        <p>Hi!, I' am web designer</p>
                      </div>
                      <div className="time"> 3 hours ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" className="dropdown-item">
                  <img
                    alt="image"
                    src="assets/img/avatar/avatar-5.jpeg"
                    className="rounded-circle dropdown-item-img"
                  />
                  <div className="dropdown-item-desc">
                    <div className="dropdownmsg d-flex">
                      <div className="">
                        <b>Sam Lyman</b>
                        <p>Hi!, I' am java developer</p>
                      </div>
                      <div className="time"> 15 mintues ago</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </li>
          <li className="dropdown dropdown-list-toggle">
            <a
              href="#"
              data-toggle="dropdown"
              className="nav-link nav-link-lg pr-0"
            >
              <span className="badge badge-secondary nav-link-badge">6</span>
              <i className="fa fa-bell-o" />
            </a>
            <div className="dropdown-menu dropdown-list dropdown-menu-right">
              <div className="dropdown-header">
                Notifications
                <div className="float-right">
                  <a href="#" className="text-white">
                    View All
                  </a>
                </div>
              </div>
              <div className="dropdown-list-content">
                <a href="#" className="dropdown-item">
                  <i className="fa fa-users text-primary" />
                  <div className="dropdown-item-desc">
                    <b>So many Users Visit your template</b>
                  </div>
                </a>
                <a href="#" className="dropdown-item">
                  <i className="fa fa-exclamation-triangle text-danger" />
                  <div className="dropdown-item-desc">
                    <b>Error message occurred....</b>
                  </div>
                </a>
                <a href="#" className="dropdown-item">
                  <i className="fa fa-users text-warning" />
                  <div className="dropdown-item-desc">
                    <b> Adding new people</b>
                  </div>
                </a>
                <a href="#" className="dropdown-item">
                  <i className="fa fa-shopping-cart text-success" />
                  <div className="dropdown-item-desc">
                    <b>Your items Arrived</b>
                  </div>
                </a>
                <a href="#" className="dropdown-item">
                  <i className="fa fa-comment text-primary" />
                  <div className="dropdown-item-desc">
                    <b>New Message received</b>
                    <div className="float-right">
                      <span className="badge badge-pill badge-danger badge-sm">
                        67
                      </span>
                    </div>
                  </div>
                </a>
                <a href="#" className="dropdown-item">
                  <i className="fa fa-users text-primary" />
                  <div className="dropdown-item-desc">
                    <b>So many Users Visit your template</b>
                  </div>
                </a>
              </div>
            </div>
          </li>
          <li className="dropdown">
            <a
              href="#"
              data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg d-flex"
            >
              <span className="mr-3 mt-2 d-none d-lg-block ">
                <span className="text-white">
                  Hello,
                  <span className="ml-1">
                    {this.state.userInfo && this.state.userInfo["NAME"]}
                  </span>
                </span>
              </span>
              <span>
                <img
                  src={photoURL}
                  alt="profile-user"
                  className="rounded-circle mr-2"
                  style={{
                    maxWidth: "35px",
                    maxHeight: "35px",
                    minHeight: "35px",
                    minWidth: "35px",
                    objectFit: "cover"
                  }}
                />
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <div className="dropdown-header noti-title text-center border-bottom pb-3">
                <h5 className="text-capitalize text-dark mb-1">
                  {this.state.userInfo && this.state.userInfo["NAME"]}
                </h5>
                <small className="text-overflow m-0">
                  {this.state.userInfo &&
                    this.wordCapitalize(this.state.userInfo["GROUP"])}
                </small>
              </div>
              <a className="dropdown-item" href="profile.html">
                <i className="mdi mdi-account-outline mr-2" />
                <span>My profile</span>
              </a>
              <Link
                className="dropdown-item"
                to={
                  this.session.get("userGroup") == "1"
                    ? "/super-admin/settings"
                    : "/home/settings"
                }
              >
                <i className="mdi mdi-settings mr-2" /> <span>Settings</span>
              </Link>
              <a className="dropdown-item" href="#">
                <i className="mdi mdi-message-outline mr-2" />
                <span>Mails</span>
              </a>
              <a className="dropdown-item" href="#">
                <i className="mdi mdi-account-multiple-outline mr-2" />
                <span>Friends</span>
              </a>
              <a className="dropdown-item" href="#">
                <i className="fe fe-calendar mr-2" /> <span>Activity</span>
              </a>
              <a className="dropdown-item" href="#">
                <i className="mdi mdi-compass-outline mr-2" />
                <span>Support</span>
              </a>
              <div className="dropdown-divider" />
              <a
                className="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <i className="mdi  mdi-logout-variant mr-2" />
                <span>Logout</span>
              </a>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}
