import React, { Component } from "react";
import NavBar from "../home/NavBar";
import Sidebar from "./Sidebar";
import Footer from "../home/Footer";
import Logout from "../dialogs/Logout";
import Dashboard from "./Dashboard";
import CompaniesToVerify from "./CompaniesToVerify";
import RegisteredCompaniesList from "./RegisteredCompaniesList";
import InterviewList from "./InterviewList";
import IntervieweeDetails from "../home/IntervieweeDetails";
import AccountSettings from "../home/AccountSettings";
import SuperAdminActivityLog from "./SuperAdminActivityLog";
import CompanyView from "./CompanyView";

import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import SessionHelper from "../../SessionHelper";

class SuperAdminHome extends Component {
  session = new SessionHelper();

  dashboardTabID = 0;
  companiesToVerifyTabID = 1;
  companiesTabID = 2;
  interviewsTabID = 3;
  activityLogTabID = 4;

  constructor(props) {
    super(props);

    if (
      this.session.get("userGroup") != "1" ||
      this.session.get("authID") == "null"
    ) {
      this.logout();
    }

    this.state = {
      showSettingsPage: false,
      viewId: props.viewId,
      navBarToggle: false
    };

    this.navBarToggle = this.navBarToggle.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.logout = this.logout.bind(this);
  }

  navBarToggle() {
    this.setState({ navBarToggle: !this.state.navBarToggle });
    if (this.state.navBarToggle) document.body.className = "p-0";
    else document.body.className = "p-0 sidebar-toggled";
  }

  handleLinkClick(linkID) {
    switch (linkID) {
      case 0:
        this.setState({ showSettingsPage: true, viewId: -1 });
        break;
    }
  }

  logout() {
    this.session.clear();
    window.location.href = "/login";
  }

  render() {
    return (
      <Router history={createBrowserHistory()}>
        <React.Fragment>
          <div class="wave -three" />
          {/* <div id="spinner" /> */}
          <div className="main-wrapper">
            <NavBar
              baseURL={this.props.baseURL}
              toggle={this.navBarToggle}
              handleLinkClick={this.handleLinkClick}
            />
            <Sidebar
              baseURL={this.props.baseURL}
              contentHandler={this.sidebarHandler}
              navBarToggle={this.state.navBarToggle}
              active={this.state.viewId}
            />
            <div className="app-content">
              <section className="section">
                <Switch>
                  <Redirect
                    from="/super-admin"
                    exact
                    to="/super-admin/dashboard"
                  />

                  <Route
                    path="/super-admin/dashboard"
                    exact
                    render={props => (
                      <Dashboard {...props} baseURL={this.props.baseURL} />
                    )}
                  />

                  <Route
                    path="/super-admin/companieslist/unverified"
                    exact
                    render={props => (
                      <CompaniesToVerify
                        {...props}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Route
                    path="/super-admin/companieslist/verified"
                    exact
                    render={props => (
                      <RegisteredCompaniesList
                        {...props}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Route
                    path="/super-admin/companyview"
                    render={props => (
                      <CompanyView {...props} baseURL={this.props.baseURL} />
                    )}
                  />

                  <Route
                    path="/super-admin/interviewlist"
                    exact
                    render={props => (
                      <InterviewList {...props} baseURL={this.props.baseURL} />
                    )}
                  />

                  <Route
                    path="/super-admin/interviewee/details"
                    exact
                    render={props => (
                      <IntervieweeDetails
                        {...props}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Route
                    path="/super-admin/activity-log"
                    exact
                    render={props => (
                      <SuperAdminActivityLog
                        {...props}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Route
                    path="/super-admin/settings"
                    exact
                    render={props => (
                      <AccountSettings
                        {...props}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Redirect to="/super-admin/dashboard" />
                </Switch>
              </section>
            </div>
            <Footer />
          </div>
          <Logout logout={this.logout} />
        </React.Fragment>
      </Router>
    );
  }
}

export default SuperAdminHome;
