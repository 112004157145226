class RestAPIHelper {
  async get(url) {
    try {
      const response = await fetch(url);
      return response.json();
    } catch (error) {
      console.log(error);
      let errRes = [];
      errRes["error"] = true;
      errRes["message"] = error;
      return errRes;
    }
  }

  async post(url, formParameters) {
    try {
      const response = await fetch(url, {
        method: "post",
        body: this.parametersConverter(formParameters)
      });
      return response.json();
    } catch (error) {
      console.log(error);
      let errRes = [];
      errRes["error"] = true;
      errRes["message"] = error;
      return errRes;
    }
  }

  parametersConverter(parameters) {
    //Converting post parameters from Javascript object to FormData object
    var formData = new FormData();
    for (var key in parameters) {
      formData.append(key, parameters[key]);
    }
    return formData;
  }
}

export default RestAPIHelper;
