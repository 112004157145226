import React, { Component } from "react";
import MessageDialog from "../dialogs/MessageDialog";
import {
  Alert,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from "reactstrap";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class AccountSettings extends Component {
  idUserName = "userName";
  idUserPhoneNumber = "userPhoneNumber";
  idPassword = "newPassword";
  idRePassword = "confirmPassword";
  idUserCurrentPassword = "currentPassword";
  idUserPhoto = "userPhoto";
  idUserPhotoLabel = "userPhotoLabel";
  idAuthToken = "authenticationToken";
  session = new SessionHelper();

  constructor(props) {
    super(props);
    this.state = {
      viewInBothTabs: false,
      havePhoto: false,
      accountPassword: null,
      dataLoaded: false,
      userInfo: null,
      editProfile: false,
      updateProfileStatus: null,
      showPasswordModal: false,
      showRemovePhotoDialog: false,
      profilePictureURL:
        props.baseURL +
        "user/photo?authenticatioToken=" +
        this.session.get("authID"),
      message: null
    };

    this.updateProfile = this.updateProfile.bind(this);
    this.togglePasswordModal = this.togglePasswordModal.bind(this);
    this.showEnterPasswordModal = this.showEnterPasswordModal.bind(this);
    this.showRemovePhotoDialog = this.showRemovePhotoDialog.bind(this);
    this.removeProfilePicture = this.removeProfilePicture.bind(this);
    this.hideRemovePhotoDialog = this.hideRemovePhotoDialog.bind(this);
    this.reloadData = this.reloadData.bind(this);
  }

  displaySelectedFIleName(id, label, defaultMsg) {
    const fileName = document.getElementById(id).value;
    if (fileName) {
      document.getElementById(label).innerHTML = fileName.substr(
        fileName.lastIndexOf("\\") + 1
      );
    } else {
      document.getElementById(label).innerHTML = defaultMsg;
    }
  }

  showEnterPasswordModal() {
    let continueUpdateProcess = false;
    const pwd = document.getElementById(this.idPassword).value;
    const rePwd = document.getElementById(this.idRePassword).value;
    if (pwd.length > 5 && pwd.length < 21 && pwd.localeCompare(rePwd) === 0) {
      continueUpdateProcess = true;
    } else if (
      pwd.length > 5 &&
      pwd.length < 21 &&
      pwd.localeCompare(rePwd) !== 0
    ) {
      this.setState({
        updateProfileStatus: 201,
        message: "Incorrect Retype password"
      });
    } else if (pwd.length > 0 && (pwd.length <= 5 || pwd.length >= 21)) {
      this.setState({
        updateProfileStatus: 201,
        message:
          "Invalid password length. It should be greater than 5 and less than 20 characters"
      });
    } else {
      continueUpdateProcess = true;
    }
    if (continueUpdateProcess) this.setState({ showPasswordModal: true });
  }

  togglePasswordModal() {
    this.setState({ showPasswordModal: false });
  }

  async updateProfile() {
    const name = this.state.userInfo["data"]["user"]["NAME"];
    const phNo = this.state.userInfo["data"]["user"]["PHONE_NO"];
    const userPhoto = document.getElementById(this.idUserPhoto).files[0];
    const pwd = document.getElementById(this.idPassword).value;
    const rePwd = document.getElementById(this.idRePassword).value;
    const accountPwd = document.getElementById(this.idUserCurrentPassword)
      .value;
    const authToken = this.session.get("authID");

    this.setState({
      updateProfileStatus: null,
      showPasswordModal: false,
      message: null
    });

    let shouldUpdatePassword = false;
    let continueUpdateProcess = false;

    if (pwd.length > 5 && pwd.length < 21 && pwd.localeCompare(rePwd) === 0) {
      shouldUpdatePassword = true;
      continueUpdateProcess = true;
    } else if (
      pwd.length > 5 &&
      pwd.length < 21 &&
      pwd.localeCompare(rePwd) !== 0
    ) {
    } else if (pwd.length > 0 && (pwd.length <= 5 || pwd.length >= 21)) {
    } else {
      shouldUpdatePassword = false;
      continueUpdateProcess = true;
    }

    if (continueUpdateProcess) {
      let parameters = Array();
      parameters.userName = name;
      parameters.userPhoneNumber = phNo;
      parameters.userPhoto = userPhoto;
      parameters.currentPassword = accountPwd;
      parameters.authenticationToken = authToken;

      if (shouldUpdatePassword) authToken.this.idPassword = pwd;

      const updateProfileURL = this.props.baseURL + "update/user";

      let apiHelper = new RestAPIHelper();
      const apiResponse = await apiHelper.post(updateProfileURL, parameters);

      if (
        apiResponse["status"] === 200 &&
        apiResponse["message"] === "Nothing updated"
      ) {
        this.setState({
          updateProfileStatus: 200.1,
          viewInBothTabs: true,
          message: "No Changes were made to your profile"
        });
      } else if (
        apiResponse["status"] === 200 &&
        apiResponse["message"] !== "Nothing updated"
      ) {
        window.location.href = "/home/settings";
        this.setState({
          updateProfileStatus: 200,
          editProfile: false,
          viewInBothTabs: true,
          message: apiResponse["message"]
        });
        // this.props.history.replace("/home/settings");
      } else {
        this.setState({
          viewInBothTabs: false,
          updateProfileStatus: apiResponse["status"],
          message: apiResponse["message"]
        });
      }

      setTimeout(() => {
        this.setState({
          message: null,
          viewInBothTabs: false
        });
      }, 3000);
    }
  }

  handleTextChange(viewID, dataIndex) {
    let x = this.state.userInfo;
    x["data"]["user"][dataIndex] = document.getElementById(viewID).value;
    this.setState({ userInfo: x });
  }

  showRemovePhotoDialog() {
    this.setState({ showRemovePhotoDialog: true });
  }

  hideRemovePhotoDialog() {
    this.setState({ showRemovePhotoDialog: false });
  }

  async removeProfilePicture() {
    this.hideRemovePhotoDialog();

    let parameters = Array();
    parameters.authenticationToken = this.session.get("authID");

    const removeProfileURL = this.props.baseURL + "user/photo/remove";

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.post(removeProfileURL, parameters);

    if (apiResponse["status"] === 200) {
      let x = this.state.userInfo;
      x["data"]["user"]["HAS_PHOTO"] = false;
      this.setState({
        userInfo: x,
        profilePictureURL:
          this.props.baseURL +
          "user/photo?authenticationToken=" +
          this.session.get("authID") +
          "/"
      });
      this.props.history.replace("/home/settings");
    } else {
      console.log("ERROR" + apiResponse["message"]);
    }
  }

  wordCapitalize(word) {
    let res = "";
    const len = word.length;
    let doCap = true;
    for (let i = 0; i < len; i++) {
      if (word.charAt(i) == " ") {
        doCap = true;
        res += word.charAt(i);
      } else if (word.charAt(i) == "_") {
        doCap = true;
        res += " ";
      } else {
        if (doCap) {
          res += word.charAt(i).toUpperCase();
          doCap = false;
        } else {
          res += word.charAt(i).toLowerCase();
        }
      }
    }
    return res;
  }

  componentDidMount() {
    this.reloadData();
  }

  async reloadData() {
    const userProfileDetails =
      this.props.baseURL +
      "user/profile?authenticationToken=" +
      this.session.get("authID");

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.get(userProfileDetails);
    this.setState({
      dataLoaded: true,
      userInfo: apiResponse,
      profilePictureURL:
        this.props.baseURL +
        "user/photo?authenticationToken=" +
        this.session.get("authID")
    });
  }

  render() {
    let view;
    if (
      this.state.editProfile &&
      this.state.dataLoaded &&
      this.state.userInfo
    ) {
      view = (
        <form
          className="container-fluid needs-validation"
          noValidate
          style={{ maxWidth: "600px" }}
        >
          <div className="form-row">
            <div className="col-md-12">
              <div className="form-label-group">
                <input
                  type="text"
                  value={this.state.userInfo["data"]["user"]["NAME"]}
                  onChange={() =>
                    this.handleTextChange(this.idUserName, "NAME")
                  }
                  id={this.idUserName}
                  className="form-control"
                  placeholder="User Name"
                />
                <label htmlFor={this.idUserName}>User Name</label>
              </div>
            </div>
          </div>
          <br />

          <div className="form-row">
            <div className="col-md-12">
              <div className="form-label-group">
                <input
                  type="Phone"
                  value={this.state.userInfo["data"]["user"]["PHONE_NO"]}
                  onChange={() =>
                    this.handleTextChange(this.idUserPhoneNumber, "PHONE_NO")
                  }
                  id={this.idUserPhoneNumber}
                  className="form-control"
                  placeholder="User Phone Number"
                  required="required"
                />
                <label htmlFor={this.idUserPhoneNumber}>
                  User Phone Number
                </label>
              </div>
            </div>
          </div>
          <br />

          <div className="form-group">
            <div className="form-row">
              <div className="col-md-6">
                <div className="form-label-group">
                  <input
                    type="Password"
                    onChange={this.clearErrorViews}
                    id={this.idPassword}
                    className="form-control"
                    placeholder="New Password"
                  />
                  <label htmlFor={this.idPassword}>New Password</label>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-label-group">
                  <input
                    type="Password"
                    onChange={this.clearErrorViews}
                    id={this.idRePassword}
                    className="form-control"
                    placeholder="Retype New Password"
                  />
                  <label htmlFor={this.idRePassword}>Retype New Password</label>
                </div>
              </div>
            </div>
          </div>

          <div className="input-group mb-2 mt-4">
            <div className="custom-file">
              <input
                type="file"
                accept="image/*"
                onChange={() =>
                  this.displaySelectedFIleName(
                    this.idUserPhoto,
                    this.idUserPhotoLabel,
                    "Select User Photo"
                  )
                }
                className="custom-file-input"
                id={this.idUserPhoto}
              />
              <label
                id={this.idUserPhotoLabel}
                className="custom-file-label"
                htmlFor="inputGroupFile01"
              >
                Select User Photo
              </label>
            </div>
          </div>

          <center>
            <a
              className="btn btn-primary btn-block mt-4"
              style={{ maxWidth: "50%" }}
              onClick={this.showEnterPasswordModal}
              href="#"
            >
              Update Profile
            </a>
          </center>
        </form>
      );
    } else if (this.state.dataLoaded && this.state.userInfo) {
      const userName = this.state.userInfo["data"]["user"]["NAME"];
      const userID = this.state.userInfo["data"]["user"]["USER_ID"];
      const idType = this.state.userInfo["data"]["user"]["ID_TYPE"];
      const emailAddress = this.state.userInfo["data"]["user"]["EMAIL_ID"];
      const phoneNumber = this.state.userInfo["data"]["user"]["PHONE_NO"];
      const createdOn = this.state.userInfo["data"]["user"]["CREATED_ON"];
      const group = this.state.userInfo["data"]["user"]["GROUP"];
      const credits = this.state.userInfo["data"]["user"]["CREDITS"];

      // const userPhotoURL =
      //   this.props.baseURL + "user/photo?authenticationToken=" + this.session.get('authID');
      const userPhotoURL = this.state.profilePictureURL;

      view = (
        <div
          className="row"
          style={{
            maxWidth: "600px"
          }}
        >
          <div className="col-md-4 col-sm-12 mb-2">
            <img
              style={{
                maxWidth: "140px",
                maxHeight: "140px",
                minHeight: "140px",
                minWidth: "140px",
                objectFit: "cover"
              }}
              src={userPhotoURL}
              className="border rounded-circle"
            />
            <br />
            {this.state.userInfo["data"]["user"]["HAS_PHOTO"] ? (
              <button
                className="btn btn-danger my-2 mx-auto py-1"
                onClick={this.showRemovePhotoDialog}
              >
                <font size="2">
                  <i className="fas fa-trash mr-2" />
                  Remove Picture
                </font>
              </button>
            ) : null}
          </div>
          <div className="col-md-8 col-sm-12">
            <b>Name : </b>
            {userName}
            <br />
            <b>{this.wordCapitalize(idType)} : </b>
            {userID}
            <br />
            <b>Email Address : </b>
            {emailAddress}
            <br />
            <b>Phone Number : </b>
            {phoneNumber}
            <br />
            <b>Account Created On : </b>
            {createdOn}
            <br />
            <b>Account type : </b>
            {this.wordCapitalize(group)}
            <br />
            {credits == null ? null : (
              <span>
                <b>Credits : </b>
                {credits}
              </span>
            )}
          </div>
          <MessageDialog
            show={this.state.showRemovePhotoDialog}
            toggle={this.hideRemovePhotoDialog}
            title="Remove Photo"
            message="Are you sure, you want to remove your profile picture? Default account picture will be set if removed."
            hideJumboDialog={this.removeProfilePicture}
            buttonName="Yes"
          />
        </div>
      );
    } else {
      view = <p>Loading data...</p>;
    }
    return (
      <div className="card mb-3">
        <div className="card-header">
          <h4 style={{ display: "inline" }}>User Account Settings</h4>
          <span style={{ float: "right" }}>
            <a
              href="#"
              onClick={() => {
                if (this.state.editProfile)
                  this.setState({
                    message: null,
                    editProfile: !this.state.editProfile
                  });
                else this.setState({ editProfile: !this.state.editProfile });
              }}
            >
              {this.state.editProfile ? (
                <i className="fas fa-times" />
              ) : (
                <i className="fas fa-edit">
                  <font face="Segoe ui">Edit Profile</font>
                </i>
              )}
            </a>
          </span>
        </div>

        <Collapse
          isOpen={
            (this.state.updateProfileStatus &&
              this.state.message &&
              this.state.editProfile) ||
            this.state.viewInBothTabs
          }
        >
          <Alert
            color={
              this.state.updateProfileStatus === 200
                ? "success"
                : this.state.updateProfileStatus === 200.1
                ? "warning"
                : "danger"
            }
            className="rounded-0"
          >
            {this.state.message}
          </Alert>
        </Collapse>

        <div className="card-body">{view}</div>

        <Modal
          isOpen={this.state.showPasswordModal}
          style={{ maxWidth: "none", position: "absolute" }}
          className="center-dialog"
        >
          <ModalHeader toggle={this.togglePasswordModal}>
            Enter your account password to proceed
          </ModalHeader>
          <ModalBody className="mb-3">
            <div className="form-row mx-3">
              <div className="col-md-8">
                <div className="form-label-group">
                  <input
                    type="password"
                    id={this.idUserCurrentPassword}
                    className="form-control"
                    placeholder="Account Password"
                    required="required"
                  />
                  <label htmlFor={this.idUserCurrentPassword}>
                    Account Password
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                <Button
                  color="primary"
                  style={{ minHeight: "49px" }}
                  onClick={this.updateProfile}
                >
                  Update Profile
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default AccountSettings;
