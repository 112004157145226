import React, { Component } from "react";
import { Link } from "react-router-dom";

class SearchCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = { showSearchResult: false };

    this.searchCandidate = this.searchCandidate.bind(this);
  }

  searchCandidate() {
    this.setState({ showSearchResult: true });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-header">
          <h4 className="page-title">Search Candidate</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/home/dashboard" className="text-light-color">
                Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Search Candidate
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h4>Search Candidate</h4>
              </div>
              <div className="card-body">
                <form className="form-horizontal">
                  <div className="form-group row">
                    <label
                      htmlFor="inputName"
                      className="col-md-3 col-form-label"
                    >
                      Name
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        id="inputName"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="inputEmail3"
                      className="col-md-3 col-form-label"
                    >
                      Email
                    </label>
                    <div className="col-md-9">
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail3"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="inputPhone"
                      className="col-md-3 col-form-label"
                    >
                      Phone
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        id="inputPhone"
                        placeholder="Phone"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="inputPhone"
                      className="col-md-3 col-form-label"
                    >
                      Upload Resume
                    </label>
                    <div className="col-md-9">
                      <div
                        className="form-group upload-btn-wrapper files mb-lg-0"
                        id="file"
                      >
                        <input
                          type="file"
                          className="form-control1"
                          multiple=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-0 mt-2 row justify-content-end">
                    <div className="col-md-9">
                      <Link
                        to="#"
                        onClick={this.searchCandidate}
                        className="btn btn-info"
                      >
                        Search Candidate
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {this.state.showSearchResult && (
          <div className="row">
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h4>Search Elements</h4>
                </div>
                <div className="card-body">
                  <table className="table-responsive">
                    <tbody>
                      <tr>
                        <th>Search details</th>
                        <th>Resume details</th>
                      </tr>
                      <tr>
                        <td
                          style={{
                            backgroundColor: "#e6f6ff",
                            width: "45%",
                            padding: "20px"
                          }}
                        >
                          Rajesh
                          <br />
                          rajesh389@gmail.com
                          <br />
                          9885127273
                        </td>

                        <td
                          style={{
                            backgroundColor: "#e6ffeb",
                            padding: "20px"
                          }}
                        >
                          Rajesh
                          <br />
                          rajesh389@gmail.com
                          <br />
                          9885127273
                          <br />
                          lorem ipsum lorem ipsumlorem ipsumlorem ipsumlorem
                          ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem
                          ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem
                          ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem
                          ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem
                          ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem
                          ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem
                          ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem
                          ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem
                          ipsumlorem ipsumlorem ipsum
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 text-right">
              <button type="submit" className="btn btn-primary">
                View Interview details
              </button>{" "}
              &nbsp; &nbsp;
              <button
                type="button"
                className="btn btn-success"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Schedule Interview
              </button>
            </div>
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SearchCandidate;
