import React, { Component } from "react";
import { Collapse, Alert } from "reactstrap";
import LoadingModal from "../dialogs/LoadingModal";
import MessageDialog from "../dialogs/MessageDialog";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class UpdateEmailAddress extends Component {
  constructor(props) {
    super(props);
    this.state = { showError: false, errorStatus: null, errorMessage: null };

    this.textInput = React.createRef();
    this.focus = this.focus.bind(this);

    this.clearErrorViews = this.clearErrorViews.bind(this);
    this.updateEmailAddress = this.updateEmailAddress.bind(this);
  }

  focus() {
    if (this.textInput.current !== null) this.textInput.current.focus();
  }

  clearErrorViews() {
    this.setState({ errorStatus: null, showError: false, errorMessage: null });
  }

  async updateEmailAddress() {
    let session = new SessionHelper();

    this.clearErrorViews();
    this.setState({ isloading: true });
    let newEmailAddress = document.getElementById("newEmailAddress").value;
    let accountPassword = document.getElementById("accountPassword").value;

    const url = this.props.baseURL + "update/emailaddress";
    let parameters = {
      authenticationToken: session.get("authID"),
      userNewEmailID: newEmailAddress,
      password: accountPassword
    };

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.post(url, parameters);
    if (apiResponse["status"] == 200) {
      this.setState({
        isloading: false,
        showJumboDialog: true,
        jumboDialogTitle: "New Email Address",
        jumboDialogMessage: apiResponse["message"]
      });
    } else {
      this.setState({
        isloading: false,
        showError: true,
        errorStatus: apiResponse["status"],
        errorMessage: apiResponse["message"]
      });
    }
    this.focus();
  }

  render() {
    return (
      <div className="card mb-3">
        <div className="card-header">
          <h4>Update Email Address</h4>
        </div>
        <Collapse
          isOpen={
            (this.state.errorStatus === 201 ||
              this.state.errorStatus === 204 ||
              this.state.errorStatus === 205 ||
              this.state.errorStatus === 216) &&
            this.state.showError
              ? true
              : false
          }
        >
          <Alert color="danger" className="rounded-0">
            {this.state.errorMessage}
          </Alert>
        </Collapse>
        <div className="card-body m-4">
          <div style={{ maxWidth: "400px", margin: "auto" }} align="center">
            <form>
              <div className="form-group">
                <div className="form-label-group">
                  <input
                    ref={
                      this.state.errorStatus === 202 && this.state.showError
                        ? this.textInput
                        : null
                    }
                    type="email"
                    id="newEmailAddress"
                    onChange={this.clearErrorViews}
                    className={
                      this.state.errorStatus === 202 && this.state.showError
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="New Email address"
                  />
                  <div className="invalid-feedback" align="left">
                    {this.state.errorMessage}
                  </div>
                  <label htmlFor="newEmailAddress" align="left">
                    New Email address
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <input
                    ref={
                      this.state.errorStatus === 203 && this.state.showError
                        ? this.textInput
                        : null
                    }
                    type="password"
                    onChange={this.clearErrorViews}
                    id="accountPassword"
                    className={
                      this.state.errorStatus === 203 && this.state.showError
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Current Password"
                    required="required"
                  />
                  <div className="invalid-feedback" align="left">
                    {this.state.errorMessage}
                  </div>
                  <label htmlFor="accountPassword" align="left">
                    Current Password
                  </label>
                </div>
              </div>
              <a
                className="btn btn-primary btn-block mt-4"
                style={{ maxWidth: "200px" }}
                href="#"
                onClick={this.updateEmailAddress}
              >
                Update Email Address
              </a>
            </form>
          </div>
        </div>
        <LoadingModal
          show={this.state.isloading}
          message="Proceesing your information. Please wait..."
        />
        <MessageDialog
          show={this.state.showJumboDialog}
          title={this.state.jumboDialogTitle}
          message={this.state.jumboDialogMessage}
          hideJumboDialog={() => {
            this.props.history.push("/home/dashboard");
          }}
          buttonName="Done"
        />
      </div>
    );
  }
}

export default UpdateEmailAddress;
