import React, { Component } from "react";
import { Link } from "react-router-dom";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

export default class Home extends Component {
  session = new SessionHelper();

  constructor(props) {
    super(props);
    this.state = { userInfo: null };
  }

  wordCapitalize(word) {
    let res = "";
    const len = word.length;
    let doCap = true;
    for (let i = 0; i < len; i++) {
      if (word.charAt(i) == " ") {
        doCap = true;
        res += word.charAt(i);
      } else if (word.charAt(i) == "_") {
        doCap = true;
        res += " ";
      } else {
        if (doCap) {
          res += word.charAt(i).toUpperCase();
          doCap = false;
        } else {
          res += word.charAt(i).toLowerCase();
        }
      }
    }
    return res;
  }

  async componentDidMount() {
    const userProfileDetails =
      this.props.baseURL +
      "user/profile?authenticationToken=" +
      this.session.get("authID");

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.get(userProfileDetails);
    if (apiResponse["status"] == "200") {
      this.setState({
        dataLoaded: true,
        userInfo: apiResponse["data"]["user"]
      });
    } else {
      console.log("Error : " + apiResponse["message"]);
    }

    const script = document.createElement("script");
    script.src = "/assets/plugins/toggle-menu/sidemenu.js";
    script.id = "sidemenu";
    document.body.replaceChild(script, document.getElementById(script.id));
  }

  render() {
    // const navToggle = this.props.navBarToggle;
    let photoURL =
      this.props.baseURL +
      "user/photo?authenticationToken=" +
      this.session.get("authID");

    return (
      <aside className="app-sidebar">
        <div className="app-sidebar__user">
          <div className="dropdown user-pro-body text-center">
            <div className="nav-link pl-1 pr-1 leading-none ">
              <img
                src={photoURL}
                alt="user-img"
                className="avatar-xl rounded-circle mb-1"
                style={{
                  objectFit: "cover"
                }}
              />
              <span className="pulse bg-success" aria-hidden="true" />
            </div>
            <div className="user-info">
              <h6 className=" mb-1 text-dark">
                {this.state.userInfo && this.state.userInfo["NAME"]}
              </h6>
              <span className="text-muted app-sidebar__user-name text-sm">
                {this.state.userInfo &&
                  this.wordCapitalize(this.state.userInfo["GROUP"])}
              </span>
            </div>
          </div>
        </div>

        <ul className="side-menu">
          <li className="slide">
            <Link to="/home/dashboard" className="side-menu__item">
              <i className="side-menu__icon fa fa-laptop" />
              <span className="side-menu__label">Dashboard</span>
              <span className="badge badge-orange nav-badge">5</span>
            </Link>
          </li>

          <li className="slide">
            <Link className="side-menu__item" data-toggle="slide" to="#">
              <i className="side-menu__icon fa fa-user" />
              <span className="side-menu__label">Interviews</span>
              <i className="angle fa fa-angle-right" />
            </Link>

            <ul className="slide-menu">
              <li>
                <Link to="/home/search/candidate" className="slide-item">
                  Search Candidate
                </Link>
              </li>

              <li>
                <Link to="/home/interview/schedule" className="slide-item">
                  Schedule Interview
                </Link>
              </li>

              <li>
                <Link to="/home/interview/list" className="slide-item">
                  Interviews List
                </Link>
              </li>
            </ul>
          </li>

          <li className="slide">
            <Link className="side-menu__item" data-toggle="slide" to="#">
              <i className="side-menu__icon fa fa-briefcase" />
              <span className="side-menu__label">Company</span>
              <i className="angle fa fa-angle-right" />
            </Link>
            <ul className="slide-menu">
              <li>
                <Link to="/home/employees/list" className="slide-item">
                  Manage Sub-Employee
                </Link>
              </li>
              <li>
                <Link to="/home/company/profile" className="slide-item">
                  Profile
                </Link>
              </li>
              <li>
                <Link to="#" className="slide-item">
                  Access Rights
                </Link>
              </li>
            </ul>
          </li>

          <li className="slide">
            <Link className="side-menu__item" to="/home/activitylog">
              <i className="side-menu__icon fa fa-cubes" />
              <span className="side-menu__label">Activity Log</span>
            </Link>
          </li>

          <li className="slide">
            <Link className="side-menu__item" to="/home/update/emailaddress">
              <i className="side-menu__icon fa fa-envelope" />
              <span className="side-menu__label">Update Email ID</span>
            </Link>
          </li>

          <li className="slide">
            <Link
              className="side-menu__item"
              to="#"
              data-toggle="modal"
              data-target="#logoutModal"
            >
              <i className="side-menu__icon mdi mdi-logout" />
              <span className="side-menu__label">Logout</span>
            </Link>
          </li>
        </ul>
      </aside>
    );
  }
}
