import React from "react";
import ReactDOM from "react-dom";
import Login from "./components/login/Login";
import Home from "./components/home/Home";
import Signup from "./components/login/Signup";
import Config from "./config";
// import "./index.css";

import { BrowserRouter as Router, Route } from "react-router-dom";
import SuperAdminHome from "./components/super-admin/SuperAdminHome";
import ForgotPassword from "./components/login/ForgotPassword";
import RecoverPassword from "./components/login/RecoverPassword";
import OAuthLogin from "./components/login/OAuthLogin";
import OAuthResponse from "./components/login/OAuthResponse";

class MyAppRoutes extends React.Component {
  constructor(props) {
    super(props);

    let i = 0,
      key;

    while (true) {
      key = localStorage.key(i);
      if (key) {
        sessionStorage.setItem(key, localStorage.getItem(key));
      } else {
        break;
      }
      i++;
    }
  }

  render() {
    const baseURL = Config.apiBaseURL;

    return (
      <Router>
        <div>
          <Route path="/" exact render={props => <Login baseURL={baseURL} />} />

          <Route
            path="/login"
            exact
            render={props => <Login baseURL={baseURL} />}
          />

          <Route
            path="/login/oauth"
            exact
            render={props => <OAuthLogin baseURL={baseURL} />}
          />

          <Route
            path="/login/oauth/response"
            exact
            render={props => <OAuthResponse {...props} baseURL={baseURL} />}
          />

          <Route
            path="/signup"
            exact
            render={props => <Signup baseURL={baseURL} />}
          />

          <Route
            path="/forgot-password"
            exact
            render={props => <ForgotPassword baseURL={baseURL} />}
          />

          <Route
            path="/home"
            render={props => <Home {...props} viewId={0} baseURL={baseURL} />}
          />

          <Route
            path="/super-admin"
            render={props => <SuperAdminHome viewId={0} baseURL={baseURL} />}
          />

          <Route
            path="/recover-password"
            exact
            render={props => <RecoverPassword {...props} baseURL={baseURL} />}
          />
        </div>
      </Router>
    );
  }
}

ReactDOM.render(<MyAppRoutes />, document.getElementById("app"));
