import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class MessageDialog extends Component {
  render() {
    const styleTag = this.props.showOnTop
      ? null
      : { maxWidth: "none", position: "absolute" };
    const classTag = this.props.showOnTop ? null : "center-dialog";

    return (
      <Modal isOpen={this.props.show} style={styleTag} className={classTag}>
        <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>
        <ModalBody>{this.props.message}</ModalBody>
        <ModalFooter>
          {this.props.toggle ? (
            <Button color="secondary" onClick={this.props.toggle}>
              Cancel
            </Button>
          ) : null}

          <Button
            color={this.props.buttonColor ? this.props.buttonColor : "primary"}
            onClick={this.props.hideJumboDialog}
          >
            {this.props.buttonName}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default MessageDialog;
