import React, { Component } from "react";
import queryString from "query-string";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class EmployeeList extends Component {
  session = new SessionHelper();

  constructor(props) {
    super(props);
    let params = { filter: "" };
    if (this.session.get("userGroup") != "1")
      params = queryString.parse(props.location.search);
    this.state = {
      employeeListArray: null,
      dataLoaded: false,
      status: null,
      message: null,
      dataLoaded: false,
      filter: params.filter
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps() {
    this.loadData();
  }

  async loadData() {
    var url =
      this.props.baseURL +
      "list/employees?authenticationToken=" +
      this.session.get("authID");
    if (this.session.get("userGroup") == "1") {
      url = url + "&companyID=" + this.props.companyID;
    }

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.get(url);
    if (apiResponse["data"] === undefined) {
      this.setState({ dataLoaded: true });
    } else {
      let x = [];
      for (var keys in apiResponse["data"]["employees"]) {
        const index = apiResponse["data"]["employees"][keys]["USER_ID"];
        x[index] = [];
        for (var data in apiResponse["data"]["employees"][keys]) {
          x[index][data] = apiResponse["data"]["employees"][keys][data];
        }
      }
      this.setState({
        dataLoaded: true,
        status: apiResponse["status"],
        message: apiResponse["message"],
        employeeListArray: x
      });
    }
  }

  render() {
    let employeeListView;
    const x =
      this.state.dataLoaded === true && this.state.status === 200
        ? true
        : false;

    if (x) {
      const employeeListData = Object.values(this.state.employeeListArray).map(
        (data, index) => {
          return (
            <tr
              key={index}
              onClick={() => {
                if (this.session.get("userGroup") == "1") {
                  this.props.showEmployeeDetails(
                    this.state.employeeListArray[data["USER_ID"]]
                  );
                } else {
                  this.props.history.push("/home/employee/details", {
                    data: this.state.employeeListArray[data["USER_ID"]]
                  });
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <td className="align-middle">{data["USER_ID"]}</td>
              <td className="align-middle">{data["NAME"]}</td>
              <td className="align-middle">{data["EMAIL_ID"]}</td>
              <td className="align-middle">{data["PHONE_NO"]}</td>

              <td className="align-middle">
                {new Date(data["CREATED_ON"]).toLocaleDateString("en-IN", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric"
                })}
              </td>
              <td className="align-middle">
                {data["IS_ACTIVE"] == "YES" ? (
                  <font color="green">
                    <b>ACTIVE</b>
                  </font>
                ) : (
                  <font color="red">
                    <b>DEACTIVE</b>
                  </font>
                )}
              </td>
              <td style={{ display: "none" }} className="align-middle">
                {data["CREATED_ON"]}
              </td>
            </tr>
          );
        }
      );
      employeeListView =
        employeeListData.length != 0 ? (
          <table
            className="table table-bordered mb-0 table-hover"
            id="dataTable"
            width="100%"
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th>Employee ID</th>
                <th>Employee Name</th>
                <th>Email ID</th>
                <th>Phone Number</th>
                <th>Account Created On</th>
                <th>Account Status</th>
                <th style={{ display: "none" }}>Time</th>
              </tr>
            </thead>
            <tbody>{employeeListData}</tbody>
          </table>
        ) : (
          <p className="mb-0">No Deactivated Accounts Found!</p>
        );
    } else if (this.state.dataLoaded === false) {
      employeeListView = <p className="mb-0">Loading data...</p>;
    } else {
      employeeListView = <p className="mb-0">No Data Found!</p>;
    }
    return (
      <React.Fragment>
        <div className="card mb-3">
          <div className="card-header align-middle">
            <h4 className="mb-0 my-1" style={{ display: "inline" }}>
              Employee's List
            </h4>
            <button
              type="button"
              className={"badge badge-primary p-2"}
              style={{ fontSize: "40px" }}
              onClick={() => {
                this.props.history.push("/home/add/employee");
              }}
              data-toggle="modal"
              data-target="#exampleModal"
              style={{ float: "right" }}
            >
              Add an Employee
            </button>
          </div>
          <div className="card-body">
            <div className="table-responsive">{employeeListView}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EmployeeList;
