import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Collapse, Alert } from "reactstrap";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class IntervieweeDetails extends Component {
  session = new SessionHelper();

  isSuperAdmin = this.session.get("userGroup") == "1" ? true : false;
  constructor(props) {
    super(props);

    let id;
    if (this.session.get("userGroup") == "1") {
      props.location.state.id === undefined &&
        (window.location.href = "/super-admin/interviewlist");
      id = props.location.state.id;
    } else {
      props.location.state.id === undefined &&
        (window.location.href = "/home/interview/list");
      id = props.location.state.id;
    }

    this.state = {
      intervieweeData: null,
      interviewData: null,
      intervieweeID: id,
      dataLoaded: false,
      showPreviousInterviewList: false
    };

    this.showPreviousInterviewList = this.showPreviousInterviewList.bind(this);
  }

  async componentDidMount() {
    if (this.isSuperAdmin) this.showPreviousInterviewList();

    var intervieweeDetailsURL =
      this.props.baseURL +
      "interviewee/id/" +
      this.state.intervieweeID +
      "?authenticationToken=" +
      this.session.get("authID");

    let apihelper = new RestAPIHelper();
    const apiResponse = await apihelper.get(intervieweeDetailsURL);
    this.setState({ intervieweeData: apiResponse });
  }

  wordCapitalize(word) {
    let res = "";
    const len = word.length;
    let doCap = true;
    for (let i = 0; i < len; i++) {
      if (word.charAt(i) == " ") {
        doCap = true;
        res += word.charAt(i);
      } else {
        if (doCap) {
          res += word.charAt(i).toUpperCase();
          doCap = false;
        } else {
          res += word.charAt(i).toLowerCase();
        }
      }
    }
    return res;
  }

  async showPreviousInterviewList() {
    this.setState({ showPreviousInterviewList: true });
    var interviewDetailsURL =
      this.props.baseURL +
      "interview-list/interviewee/" +
      this.state.intervieweeID +
      "?authenticationToken=" +
      this.session.get("authID");

    let apiHelper = new RestAPIHelper();
    const apiRespone = await apiHelper.get(interviewDetailsURL);
    if (apiRespone["status"] === 200) {
      this.setState({ interviewData: apiRespone });
    } else {
      this.setState({
        showMessageView: true,
        showPreviousInterviewList: false,
        messageContent: apiRespone["message"]
      });
      setTimeout(() => {
        this.setState({
          showMessageView: false
        });
      }, 5000);
    }
  }

  render() {
    let intervieweeID, idType, intervieweeName, emailAddress, phoneNumber;
    let interviewDataTable;
    const userPhotoURL =
      this.props.baseURL + "user/photo?userID=" + this.state.intervieweeID;

    if (
      this.state.intervieweeData != null &&
      this.state.intervieweeData["status"] == 200 &&
      this.state.intervieweeData["data"] != null
    ) {
      intervieweeID = this.state.intervieweeID;
      idType = this.state.intervieweeData["data"]["user"]["ID_TYPE"];
      intervieweeName = this.state.intervieweeData["data"]["user"]["NAME"];
      emailAddress = this.state.intervieweeData["data"]["user"]["EMAIL_ID"];
      phoneNumber = this.state.intervieweeData["data"]["user"]["PHONE_NO"];
    }

    if (
      this.state.interviewData != null &&
      this.state.interviewData["status"] == 200 &&
      this.state.interviewData["data"] != null
    ) {
      const d2 = Object.values(
        this.state.interviewData["data"]["Interview List"]
      ).map((data, index) => {
        return (
          <tr key={index}>
            <td>{data["COMPANY NAME"]}</td>
            <td>{data["POSITION"]}</td>
            <td>
              {data["HIRED"] == null ? (
                <span className="text-muted">
                  <h6>On Hold</h6>
                </span>
              ) : data["HIRED"] == 1 ? (
                <h6>Selected</h6>
              ) : (
                "Not Selected"
              )}
            </td>
            <td>
              {data["STATUS"] == null ? "0" : data["STATUS"]} /
              {data["INTERVIEW STAGES"]}
            </td>
            {"CTC OFFERED" in data ? (
              <td>
                {data["CTC OFFERED"] == null ? (
                  <span className="small text-muted">-- Not Specified --</span>
                ) : (
                  data["CTC OFFERED"]
                )}
              </td>
            ) : null}
            <td>{data["INTERVIEWED ON"]}</td>
          </tr>
        );
      });

      interviewDataTable = (
        <table
          className="table table-bordered mb-0"
          width="100%"
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th>Company Name</th>
              <th>For Position</th>
              <th>Status</th>
              <th>Stages Qualified</th>
              {"CTC OFFERED" in
              this.state.interviewData["data"]["Interview List"][1] ? (
                <th>CTC Offered</th>
              ) : null}
              <th>Interviewed On</th>
            </tr>
          </thead>
          <tbody>{d2}</tbody>
        </table>
      );
    } else if (!this.state.showPreviousInterviewList && !this.isSuperAdmin) {
      interviewDataTable = (
        <p className="ml-2 mb-0">
          <a href="#" onClick={this.showPreviousInterviewList}>
            <b>Show </b>
          </a>
          previous attended interview list. It will cost you 10 credits.
        </p>
      );
    } else if (this.state.dataLoaded === false) {
      interviewDataTable = <p className="ml-2 mb-0">Loading data...</p>;
    } else {
      interviewDataTable = <p className="ml-2 mb-0">No records found!</p>;
    }

    intervieweeID = intervieweeID != null ? intervieweeID : "User Id : ---";
    idType = idType != null ? this.wordCapitalize(idType) : "Id Type : ---";
    intervieweeName =
      intervieweeName != null
        ? this.wordCapitalize(intervieweeName)
        : "Name : ---";
    emailAddress = emailAddress != null ? emailAddress : "Email Address : ---";
    phoneNumber = phoneNumber != null ? phoneNumber : "Phone Number : ---";

    return (
      <React.Fragment>
        <div className="page-header">
          <h4 className="page-title">Candidate Details</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/home/dashboard" className="text-light-color">
                Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Candidate Details
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-md-4 col-sm-12">
            <img
              src={userPhotoURL}
              className="border rounded-circle"
              style={{
                maxWidth: "140px",
                maxHeight: "140px",
                minHeight: "140px",
                minWidth: "140px",
                objectFit: "cover"
              }}
            />
            <p />
            <h5 className=" lh-3 mg-b-20">{intervieweeName}</h5>
            {idType} : {intervieweeID}
            <br />
            {emailAddress}
            <br />
            Phone No : {phoneNumber}
            <br />
            <br />
            <a href="#">
              <u>Resume</u>
            </a>
            <br />
            <br />
            <p>
              <a href="#">
                <b>Show </b>
              </a>
              attended interview list. It will cost you 10 credits.
            </p>
          </div>

          <div className="col-md-8 col-sm-12">
            <ul className="cbp_tmtimeline">
              <li>
                <time
                  className="cbp_tmtime text-white"
                  datetime="2017-11-04T18:30"
                >
                  <span className="hidden mr-2">25/11/2018</span>
                  <span className="large mr-2">Now</span>
                </time>
                <div className="cbp_tmicon bg-warning">
                  <i className="zmdi zmdi-account" />
                </div>
                <div className="cbp_tmlabel">
                  <h2>Joined</h2>
                </div>
              </li>

              <li>
                <time className="cbp_tmtime" datetime="2017-11-04T03:45">
                  <span className="mr-2">03:45 AM</span>
                  <span className="mr-2">Today</span>
                </time>
                <div className="cbp_tmicon bg-primary">
                  <i className="zmdi zmdi-label" />
                </div>
                <div className="cbp_tmlabel">
                  <h2>Accepted</h2>
                </div>
              </li>
              <li>
                <time className="cbp_tmtime" datetime="2017-11-03T13:22">
                  <span className="mr-2">01:22 PM</span>{" "}
                  <span className="mr-2">Yesterday</span>
                </time>
                <div className="cbp_tmicon bg-success">
                  <i className="zmdi zmdi-case" />
                </div>
                <div className="cbp_tmlabel">
                  <h2>Offered</h2>
                  <p className="text-sm">14 Lakhs per annum</p>
                </div>
              </li>
              <li>
                <time className="cbp_tmtime" datetime="2017-10-22T12:13">
                  <span className="mr-2">12:13 PM</span>{" "}
                  <span className="mr-2">Two weeks ago</span>
                </time>
                <div className="cbp_tmicon bg-info">
                  <i className="zmdi zmdi-pin" />
                </div>
                <div className="cbp_tmlabel">
                  <h2>Attended Final Phase of Interview</h2>

                  <p className=" text-sm">sdfsdfs</p>
                </div>
              </li>
              <li>
                <time className="cbp_tmtime" datetime="2017-10-22T12:13">
                  <span className="mr-2">12:13 PM</span>{" "}
                  <span className="mr-2">Two weeks ago</span>
                </time>
                <div className="cbp_tmicon bg-orange">
                  <i className="zmdi zmdi-camera" />
                </div>
                <div className="cbp_tmlabel">
                  <h2>HR Interview</h2>
                </div>
              </li>
              <li>
                <time className="cbp_tmtime" datetime="2017-11-03T13:22">
                  <span className="mr-2">01:22 PM</span>{" "}
                  <span className="mr-2">Two weeks ago</span>
                </time>
                <div className="cbp_tmicon bg-secondary">
                  <i className="zmdi zmdi-case" />
                </div>
                <div className="cbp_tmlabel">
                  <h2>Processing Interview</h2>
                  <p className="text-sm">
                    You have a meeting at Head Office Today.
                  </p>
                </div>
              </li>
              <li>
                <time className="cbp_tmtime" datetime="2017-10-22T12:13">
                  <span className="mr-2">12:13 PM</span>{" "}
                  <span className="mr-2">Month ago</span>
                </time>
                <div className="cbp_tmicon bg-danger">
                  <i className="zmdi zmdi-pin" />
                </div>
                <div className="cbp_tmlabel">
                  <h2>On Hold</h2>
                  <blockquote className="mb-0 text-sm">
                    Great place, feeling like in home.
                  </blockquote>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* <div className="card mb-3">
        <div className="card-header">
          <i className="fas fa-table mr-1" />
          Interviewee Details
        </div>
        <div className="card-body">
          <div
            className="row"
            style={{
              maxWidth: "600px",
              margin: "auto",
              marginLeft: "1%"
            }}
          >
            <div className="col-md-4 col-sm-12 mb-2">
              <img
                style={{
                  maxWidth: "140px",
                  maxHeight: "140px",
                  minHeight: "140px",
                  minWidth: "140px",
                  objectFit: "cover"
                }}
                src={userPhotoURL}
                className="border rounded-circle"
              />
            </div>
            <div className="col-md-8 col-sm-12">
              {intervieweeName}
              <br />
              {idType} : {intervieweeID}
              <br />
              {emailAddress}
              <br />
              {phoneNumber}
              <br />
              <a href="#">
                <u>Resume</u>
              </a>
            </div>
          </div>
          <br />
          <div className="table-responsive">
            <h5
              className="ml-2 mt-2"
              style={{
                display: this.state.showPreviousInterviewList
                  ? "inline"
                  : "none"
              }}
            >
              Interviews Attended
            </h5>
            {interviewDataTable}
          </div>
        </div>
        <div className="card-footer text-muted p-0">
          <Collapse isOpen={this.state.showMessageView}>
            <Alert color="danger" className="rounded-0 mb-0">
              {this.state.messageContent}
            </Alert>
          </Collapse>
        </div>
      </div> */}
      </React.Fragment>
    );
  }
}

export default IntervieweeDetails;
