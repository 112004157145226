import React, { Component } from "react";
import queryString from "query-string";
import { Link } from "react-router-dom";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class CompanyInterviewList extends Component {
  session = new SessionHelper();

  constructor(props) {
    super(props);
    let params = queryString.parse(props.location.search);
    this.state = {
      intervieweeListArray: null,
      filter: params.filter,
      dataLoaded: false,
      status: null,
      message: null,
      canViewCTC: false
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps() {
    this.setState({ filter: this.props.filter });
  }

  async loadData() {
    var url = this.props.baseURL + "interview-list/company/";

    if (this.props.cid != null) url = url + this.props.cid;

    url = url + "?authenticationToken=" + this.session.get("authID");

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.get(url);
    if (apiResponse["data"] === undefined) {
      this.setState({
        dataLoaded: true
      });
    } else {
      let x = [];
      for (var keys in apiResponse["data"]["Interview List"]) {
        const index =
          apiResponse["data"]["Interview List"][keys]["INTERVIEWEE ID"];
        const interviewedOn =
          apiResponse["data"]["Interview List"][keys]["INTERVIEWED ON"];
        if (!x[index]) x[index] = [];
        x[index][interviewedOn] = [];
        for (var data in apiResponse["data"]["Interview List"][keys]) {
          x[index][interviewedOn][data] =
            apiResponse["data"]["Interview List"][keys][data];
        }
      }
      this.setState({
        dataLoaded: true,
        status: apiResponse["status"],
        message: apiResponse["message"],
        intervieweeListArray: x,
        canViewCTC:
          "CTC OFFERED" in apiResponse["data"]["Interview List"][1]
            ? true
            : false
      });
    }
  }

  updateScript(sorder) {}

  render() {
    let list1, list2;
    let interviewListView, archiveInterviewListView;
    const x =
      this.state.intervieweeListArray != null &&
      this.state.status == 200 &&
      this.state.message != "No Records Found"
        ? true
        : false;
    if (x) {
      const interviewListData = Object.values(
        this.state.intervieweeListArray
      ).map((data1, index1) => {
        return Object.values(data1).map((data, index) => {
          const key = index + "-" + index1;
          let options = {
            day: "numeric",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric"
          };
          let today = new Date(data["INTERVIEWED ON"]);
          return (
            <tr
              key={key}
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (this.session.get("userGroup") == "1") {
                  this.props.showInterviewUpdateView(data);
                } else {
                  this.props.history.push("/home/update/interview", {
                    interviewData: data
                  });
                }
              }}
            >
              <td className="align-middle">
                <label
                  className="custom-anchor"
                  onClick={e => {
                    e.stopPropagation();
                    if (this.session.get("userGroup") == "1") {
                      this.props.showIntervieweeDetails(data["INTERVIEWEE ID"]);
                    } else {
                      this.props.history.push("/home/interviewee/details", {
                        id: data["INTERVIEWEE ID"]
                      });
                    }
                  }}
                >
                  {data["INTERVIEWEE ID"]}
                </label>
              </td>
              <td className="align-middle">{data["INTERVIEWEE NAME"]}</td>
              <td className="align-middle">
                {data["POSITION"] ? data["POSITION"] : "Not Specified"}
              </td>
              <td className="align-middle">
                {data["STATUS"] == null ? "---" : data["STATUS"]}
              </td>
              {this.state.canViewCTC ? (
                <td className="align-middle">
                  {data["CTC OFFERED"] == null ? (
                    <span className="small text-muted">
                      -- Not Specified --
                    </span>
                  ) : (
                    data["CTC OFFERED"]
                  )}
                </td>
              ) : null}

              <td className="align-middle">
                {data["HIRED"] == null ? (
                  <span
                    className="badge badge-orange"
                    style={{ width: "100%" }}
                  >
                    On Hold
                  </span>
                ) : data["HIRED"] == 1 ? (
                  <span
                    className="badge badge-success"
                    style={{ width: "100%" }}
                  >
                    Selected
                  </span>
                ) : (
                  <span
                    className="badge badge-danger"
                    style={{ width: "100%" }}
                  >
                    Not Selected
                  </span>
                )}
              </td>

              <td className="align-middle">
                {today.toLocaleDateString("en-IN", options)}
              </td>

              <td className="align-middle p-2">
                <a
                  className="btn btn-sm btn-success"
                  style={{ height: "150%" }}
                  href="#"
                >
                  <font size="2">Arrived</font>
                </a>
                &nbsp;
                <a className="btn btn-sm btn-primary" href="#">
                  <font size="2">Re-Schedule</font>
                </a>
                &nbsp;
                <a className="btn btn-sm btn-danger" href="#">
                  <font size="2">Close</font>
                </a>
              </td>
              <td style={{ display: "none" }}>{data["INTERVIEWED ON"]}</td>
            </tr>
          );
        });
      });

      interviewListView =
        interviewListData.length > 0 ? (
          <table
            className="table table-bordered mb-0 table-hover text-nowrap"
            id="dataTable"
            width="100%"
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th>Candidate Id</th>
                <th>Candidate Name</th>
                <th>For Position</th>
                <th>Stages Qualified</th>
                {this.state.canViewCTC ? <th>CTC Offered</th> : null}
                <th>Status</th>
                <th>Interviewed On</th>
                <th>Actions</th>
                <th style={{ display: "none" }}>Time</th>
              </tr>
            </thead>
            <tbody>{interviewListData}</tbody>
          </table>
        ) : (
          <p className="mb-0">No Pending Interviews!</p>
        );

      archiveInterviewListView =
        interviewListData.length > 0 ? (
          <table
            className="table table-bordered mb-0 table-hover text-nowrap"
            id="dataTable"
            width="100%"
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th>Candidate Id</th>
                <th>Candidate Name</th>
                <th>For Position</th>
                <th>Stages Qualified</th>
                {this.state.canViewCTC ? <th>CTC Offered</th> : null}
                <th>Status</th>
                <th>Interviewed On</th>
                <th>Actions</th>
                <th style={{ display: "none" }}>Time</th>
              </tr>
            </thead>
            <tbody>{interviewListData}</tbody>
          </table>
        ) : (
          <p className="mb-0">No Pending Interviews!</p>
        );
    } else if (this.state.dataLoaded === false) {
      interviewListView = <p className="mb-0">Loading data...</p>;
    } else {
      interviewListView = <p className="mb-0">No Data Found!</p>;
    }
    return (
      <React.Fragment>
        {this.props.showHeader && (
          <div className="page-header">
            <h4 className="page-title">Interviews List</h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/home/dashboard" className="text-light-color">
                  Dashboard
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Interviews List
              </li>
            </ol>
          </div>
        )}

        <div className="card mb-4">
          <div className="card-header">
            <h4>Upcoming Interviews</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">{interviewListView}</div>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-header">
            <h4>Ongoing Interview List</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">{archiveInterviewListView}</div>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-header">
            <h4>Archived Interview List</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">{archiveInterviewListView}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CompanyInterviewList;
