import React, { Component } from "react";
import { Modal, ModalBody, Progress } from "reactstrap";

class LoadingModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.show}
        className="center-dialog"
        style={{ width: "100%", position: "absolute" }}
      >
        <ModalBody>
          <center className="mx-2">{this.props.message}</center>
          <br />
          <Progress animated value={100} />
        </ModalBody>
      </Modal>
    );
  }
}

export default LoadingModal;
