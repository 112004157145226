import React, { Component } from "react";

import CompanyInterviewList from "../home/CompanyInterviewList";
import UpdateInterview from "../home/UpdateInterview";
import IntervieweeDetails from "../home/IntervieweeDetails";
import EmployeeList from "../home/EmployeeList";
import EmployeeDetails from "../home/EmployeeDetails";

import LoadingModal from "../dialogs/LoadingModal";
import { Modal, ModalBody } from "reactstrap";
import ActivityLog from "../home/ActivityLog";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class CompanyView extends Component {
  session = new SessionHelper();

  selectedTab = 1;
  constructor(props) {
    super(props);
    this.state = {
      companyData: props.location.state.companyData,
      isloading: false,
      showInnerDialog: false,
      showInterviewUpdateView: false,
      showIntervieweeDetails: false
    };

    this.resetTab1 = this.resetTab1.bind(this);
    this.resetTab2 = this.resetTab2.bind(this);
    this.resetTab3 = this.resetTab3.bind(this);
    this.showInnerDialog = this.showInnerDialog.bind(this);
    this.clearTabViewState = this.clearTabViewState.bind(this);
    this.showEmployeeDetails = this.showEmployeeDetails.bind(this);
    this.showIntervieweeDetails = this.showIntervieweeDetails.bind(this);
    this.showInterviewUpdateView = this.showInterviewUpdateView.bind(this);
    this.toggleCompanyActiveState = this.toggleCompanyActiveState.bind(this);
  }

  showInnerDialog() {
    const innerDialogMessage =
      this.state.companyData["IS_VERIFIED"] == "1"
        ? "Are you sure, you want to unverify this company?"
        : "Are you sure, you want to mark this company as verifed?";
    this.setState({
      showInnerDialog: true,
      innerDialogMessage: innerDialogMessage
    });
  }

  async toggleCompanyActiveState() {
    this.setState({ isloading: true, showInnerDialog: false });

    const verificationValue =
      this.state.companyData["IS_VERIFIED"] == "1" ? 0 : 1;
    const url = this.props.baseURL + "verify/company";

    let parameters = {
      authenticationToken: this.session.get("authID"),
      companyID: this.state.companyData["ID"],
      verificationValue: verificationValue
    };

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.post(url, parameters);

    this.setState({ isloading: false });
    if (apiResponse["status"] === 200) {
      let x = this.state.companyData;
      x["IS_VERIFIED"] = x["IS_VERIFIED"] == "1" ? "0" : "1";
      this.setState({ companyData: x });
    } else {
      alert("Error Occured");
    }
  }

  showInterviewUpdateView(interviewData) {
    this.clearTabViewState();
    this.setState({
      data: interviewData,
      showInterviewUpdateView: true
    });
  }

  showIntervieweeDetails(id) {
    this.clearTabViewState();
    this.setState({
      intervieweeID: id,
      showIntervieweeDetails: true
    });
  }

  showEmployeeDetails(employeeData) {
    this.clearTabViewState();
    this.setState({ employeeData: employeeData, showEmployeeDetails: true });
  }

  clearTabViewState() {
    this.setState({
      data: null,
      employeeData: null,
      intervieweeID: null,
      showIntervieweeDetails: false,
      showInterviewUpdateView: false,
      showEmployeeDetails: false
    });
  }

  resetTab1() {
    this.clearTabViewState();
    this.setState({
      data: null,
      intervieweeID: null,
      showIntervieweeDetails: false,
      showInterviewUpdateView: false
    });
    this.selectedTab = 1;
  }

  resetTab2() {
    this.clearTabViewState();
    this.setState({
      employeeData: null,
      showEmployeeDetails: false
    });
    this.selectedTab = 2;
  }

  resetTab3() {
    this.clearTabViewState();
    // this.setState({});
    this.selectedTab = 3;
  }

  wordCapitalize(word) {
    let res = "";
    const len = word.length;
    let doCap = true;
    for (let i = 0; i < len; i++) {
      if (word.charAt(i) == " ") {
        doCap = true;
        res += word.charAt(i);
      } else {
        if (doCap) {
          res += word.charAt(i).toUpperCase();
          doCap = false;
        } else {
          res += word.charAt(i).toLowerCase();
        }
      }
    }
    return res;
  }

  render() {
    let tab1View, tab2View, tab3View;
    if (this.state.showInterviewUpdateView) {
      tab1View = (
        <UpdateInterview
          baseURL={this.props.baseURL}
          interviewData={this.state.data}
        />
      );
    } else if (this.state.showIntervieweeDetails) {
      tab1View = (
        <IntervieweeDetails
          baseURL={this.props.baseURL}
          id={this.state.intervieweeID}
        />
      );
    } else if (
      !this.state.showInterviewUpdateView &&
      !this.state.showIntervieweeDetails &&
      this.selectedTab === 1
    ) {
      tab1View = (
        <CompanyInterviewList
          {...this.props}
          cid={this.state.companyData["ID"]}
          baseURL={this.props.baseURL}
          showIntervieweeDetails={this.showIntervieweeDetails}
          showInterviewUpdateView={this.showInterviewUpdateView}
        />
      );
    } else if (this.state.showEmployeeDetails) {
      tab2View = (
        <EmployeeDetails
          baseURL={this.props.baseURL}
          employeeData={this.state.employeeData}
        />
      );
    } else if (!this.state.showEmployeeDetails && this.selectedTab === 2) {
      tab2View = (
        <EmployeeList
          baseURL={this.props.baseURL}
          companyID={this.state.companyData["ID"]}
          showEmployeeDetails={this.showEmployeeDetails}
        />
      );
    } else if (this.selectedTab === 3) {
      tab3View = (
        <ActivityLog
          baseURL={this.props.baseURL}
          companyID={this.state.companyData["ID"]}
        />
      );
    }

    return (
      <div className="container m-0 p-0">
        <div>
          <nav className="card">
            <div className="m-3">
              <h2 className="mb-3">{this.state.companyData["NAME"]}</h2>
              <h6>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ align: "right" }}>
                        {this.wordCapitalize(this.state.companyData["ID_TYPE"])}
                      </td>
                      <td>
                        <span className="mx-2"> : </span>
                        {this.state.companyData["USER_ID"]}
                      </td>
                    </tr>

                    <tr>
                      <td>Company ID</td>
                      <td>
                        <span className="mx-2"> : </span>
                        {this.state.companyData["ID"]}
                      </td>
                    </tr>

                    <tr>
                      <td>Email Address</td>
                      <td>
                        <span className="mx-2"> : </span>
                        {this.state.companyData["EMAIL_ADDRESS"]}
                      </td>
                    </tr>

                    <tr>
                      <td>Phone Number</td>
                      <td>
                        <span className="mx-2"> : </span>
                        {this.state.companyData["PHONE_NO"]}
                      </td>
                    </tr>

                    <tr>
                      <td>Website</td>
                      <td>
                        <span className="mx-2"> : </span>
                        <a
                          className="custom-anchor"
                          href={"http://" + this.state.companyData["WEBSITE"]}
                        >
                          {this.state.companyData["WEBSITE"]}
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td>Account created on</td>
                      <td>
                        <span className="mx-2"> : </span>
                        {new Date(
                          this.state.companyData["CREATED_ON"]
                        ).toLocaleDateString("en-IN", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric"
                        })}
                      </td>
                    </tr>

                    <tr>
                      <td>Credits</td>
                      <td>
                        <span className="mx-2"> : </span>
                        {this.state.companyData["CREDITS"]}
                      </td>
                    </tr>

                    <tr>
                      <td>Company verified</td>
                      <td>
                        <span className="mx-2"> : </span>
                        {this.state.companyData["IS_VERIFIED"] == "1"
                          ? "Yes"
                          : "No"}
                      </td>
                    </tr>

                    <tr>
                      <td>Total users</td>
                      <td>
                        <span className="mx-2"> : </span>
                        {this.state.companyData["TOTAL_EMPLOYEES"]}
                      </td>
                    </tr>

                    <tr>
                      <td>Company description</td>
                      <td>
                        <span className="mx-2"> : </span>
                        {this.state.companyData["DESCRIPTION"]}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </h6>
              <button
                className={
                  this.state.companyData["IS_VERIFIED"] == "1"
                    ? "btn btn-danger mt-3"
                    : "btn btn-success mt-3"
                }
                type="button"
                onClick={this.showInnerDialog}
              >
                {this.state.companyData["IS_VERIFIED"] == "1"
                  ? "Unverify Company"
                  : "Verify Company"}
              </button>

              <Modal
                isOpen={this.state.showInnerDialog}
                className="center-dialog"
                style={{ maxWidth: "none", position: "absolute" }}
              >
                <ModalBody>
                  {this.state.innerDialogMessage}
                  <br />
                  <div className="mt-3">
                    <button
                      className={
                        this.state.companyData["IS_VERIFIED"] == "1"
                          ? "btn btn-danger"
                          : "btn btn-success"
                      }
                      type="button"
                      onClick={this.toggleCompanyActiveState}
                      style={{ float: "right" }}
                    >
                      Yes
                    </button>

                    <button
                      className="btn btn-secondary mr-3"
                      type="button"
                      onClick={() =>
                        this.setState({
                          showInnerDialog: false
                        })
                      }
                      style={{ float: "right" }}
                    >
                      No
                    </button>
                  </div>
                </ModalBody>
              </Modal>
              <LoadingModal
                show={this.state.isloading}
                message="Waiting for server response. Please Wait..."
              />
            </div>

            <div className="nav nav-pills nav-fill" id="nav-tab" role="tablist">
              <a
                className="nav-item nav-link active"
                id="nav-interview-tab"
                data-toggle="tab"
                href="#nav-interview"
                onClick={this.resetTab1}
                role="tab"
                aria-controls="nav-interview"
                aria-selected="true"
              >
                Interview List
              </a>

              <a
                className="nav-item nav-link"
                id="nav-employee-tab"
                data-toggle="tab"
                href="#nav-employee"
                onClick={this.resetTab2}
                role="tab"
                aria-controls="nav-employee"
                aria-selected="false"
              >
                Sub Employee
              </a>

              <a
                className="nav-item nav-link"
                id="nav-activity-tab"
                data-toggle="tab"
                href="#nav-activity"
                onClick={this.resetTab3}
                role="tab"
                aria-controls="nav-activity"
                aria-selected="false"
              >
                Activity Log
              </a>
            </div>
          </nav>

          <div className="card-body p-0">
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-interview"
                role="tabpanel"
                aria-labelledby="nav-interview-tab"
              >
                {tab1View}
              </div>

              <div
                className="tab-pane fade"
                id="nav-employee"
                role="tabpanel"
                aria-labelledby="nav-employee-tab"
              >
                {tab2View}
              </div>

              <div
                className="tab-pane fade"
                id="nav-activity"
                role="tabpanel"
                aria-labelledby="nav-activity-tab"
              >
                {tab3View}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyView;
