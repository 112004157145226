import React, { Component } from "react";
import { Modal, ModalBody, Progress } from "reactstrap";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class CompanyDialog extends Component {
  constructor(props) {
    if (!props) return;
    super(props);
    this.state = {
      companyData: props.companyData,
      showInnerDialog: false,
      showLoadingBar: false
    };

    this.showInnerDialog = this.showInnerDialog.bind(this);
    this.hideInnerDialog = this.hideInnerDialog.bind(this);
    this.verifyCompany = this.verifyCompany.bind(this);
    this.verifyCompany1 = this.verifyCompany1.bind(this);
  }

  showInnerDialog() {
    const innerDialogMessage =
      this.state.companyData["IS_VERIFIED"] == "1"
        ? "Are you sure, you want to unverify this company?"
        : "Are you sure, you want to mark this company as verifed?";
    this.setState({
      showInnerDialog: true,
      innerDialogMessage: innerDialogMessage
    });
  }

  hideInnerDialog() {
    this.setState({ showInnerDialog: false });
  }

  verifyCompany1() {
    setTimeout(this.verifyCompany, 1000);
  }

  wordCapitalize(word) {
    let res = "";
    const len = word.length;
    let doCap = true;
    for (let i = 0; i < len; i++) {
      if (word.charAt(i) == " ") {
        doCap = true;
        res += word.charAt(i);
      } else if (word.charAt(i) == "_") {
        doCap = true;
        res += " ";
      } else {
        if (doCap) {
          res += word.charAt(i).toUpperCase();
          doCap = false;
        } else {
          res += word.charAt(i).toLowerCase();
        }
      }
    }
    return res;
  }

  async verifyCompany() {
    let session = new SessionHelper();
    this.setState({ showLoadingBar: true, showInnerDialog: false });

    const url = this.props.baseURL + "verify/company";

    const verificationValue =
      this.state.companyData["IS_VERIFIED"] == "1" ? 0 : 1;

    let parameters = {
      authenticationToken: session.get("authID"),
      companyID: this.state.companyData["C_ID"],
      verificationValue: verificationValue
    };

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.post(url, parameters);
    this.setState({ showLoadingBar: false });
    if (apiResponse["status"] === 200) {
      this.props.hide();
      this.props.processArray();
    } else {
      console.log("Error:" + apiResponse["message"]);
    }
  }

  render() {
    if (this.props.show) {
      if (!this.state.companyData)
        this.setState({ companyData: this.props.companyData });
      const companyData = this.props.companyData;
      const companyName = companyData["NAME"];
      const companyID = companyData["C_ID"];
      const website = companyData["WEBSITE"];
      const phoneNumber = companyData["PHONE_NO"];
      const description = companyData["DESCRIPTION"];
      const emailAddress = companyData["EMAIL_ADDRESS"];
      const credits = companyData["CREDITS"];
      const totalEmployees = companyData["TOTAL_EMPLOYEES"];
      const createdOn = companyData["CREATED_ON"];
      // const designation = companyData["DESIGNATION"];

      const logo = this.props.baseURL + "company/photo?id=" + companyID;

      const buttonClass =
        companyData["IS_VERIFIED"] == "1"
          ? "btn btn-danger"
          : "btn btn-success";
      const buttontext =
        companyData["IS_VERIFIED"] == "1"
          ? "Unverify Company"
          : "Verify Company";

      return (
        <Modal
          isOpen={this.props.show}
          className="center-dialog"
          style={{ maxWidth: "none", position: "absolute" }}
        >
          <ModalBody>
            <div className="row">
              <div
                className="ml-3 mb-3"
                style={{ float: "left", width: "25", overflow: "hidden" }}
              >
                <img
                  style={{
                    maxWidth: "140px",
                    maxHeight: "140px",
                    minHeight: "140px",
                    minWidth: "140px",
                    objectFit: "cover",
                    borderRadius: "8px"
                  }}
                  src={logo}
                  className="mt-2"
                />
              </div>
              <div
                className="ml-3 mr-3 mb-3"
                style={{ float: "left", width: "75", overflow: "hidden" }}
              >
                <b>Company ID : </b>
                {companyID}
                <br />
                <b>Company Name : </b> {companyName}
                <br />
                <b>
                  {this.wordCapitalize(companyData["COMPANY_UNIQUE_ID_TYPE"])} :
                </b>
                {companyData["COMPANY_UNIQUE_ID"]}
                <br />
                <b>Website :</b> <a href={website}>{website}</a>
                <br />
                <b>Phone Number : </b>
                {phoneNumber}
                <br />
                <b>Email Address : </b>
                {emailAddress}
                <br />
                {/* <b>Designation : </b>{designation}
                <br /> */}
                <b>Employee Accounts : </b>
                {totalEmployees}
                <br />
                <b>Credits : </b>
                {credits}
                <br />
                <b>Account Created On : </b>
                {createdOn}
              </div>
            </div>
            <div>
              <button
                className={buttonClass}
                type="button"
                onClick={this.showInnerDialog}
                style={{ float: "right" }}
                disabled={this.state.showLoadingBar}
              >
                {buttontext}
              </button>

              <button
                className="btn btn-secondary mr-3"
                type="button"
                onClick={this.props.hide}
                style={{ float: "right" }}
                disabled={this.state.showLoadingBar}
              >
                Cancel
              </button>
            </div>
          </ModalBody>
          {this.state.showLoadingBar ? (
            <div className="ml-2 mr-2 mb-2">
              <Progress animated value={100} style={{ maxHeight: "6px" }} />
            </div>
          ) : null}

          <Modal
            isOpen={this.state.showInnerDialog}
            className="center-dialog"
            style={{ maxWidth: "none", position: "absolute" }}
          >
            <ModalBody>
              {this.state.innerDialogMessage}
              <br />
              <div className="mt-3">
                <button
                  className={
                    this.state.companyData["IS_VERIFIED"] == "1"
                      ? "btn btn-danger"
                      : "btn btn-success"
                  }
                  type="button"
                  onClick={this.verifyCompany}
                  style={{ float: "right" }}
                >
                  Yes
                </button>

                <button
                  className="btn btn-secondary mr-3"
                  type="button"
                  onClick={this.hideInnerDialog}
                  style={{ float: "right" }}
                >
                  No
                </button>
              </div>
            </ModalBody>
          </Modal>
        </Modal>
      );
    } else {
      return true;
    }
  }
}

export default CompanyDialog;
