import React, { Component } from "react";
import CompanyDialog from "../dialogs/CompanyDialog";
import MessageDialog from "../dialogs/MessageDialog";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class CompaniesToVerify extends Component {
  constructor(props) {
    super(props);
    this.state = { showCompanyDetails: false, dataLoaded: false };

    this.showCompanyDetailsDialog = this.showCompanyDetailsDialog.bind(this);
    this.hideCompanyDetailsDialog = this.hideCompanyDetailsDialog.bind(this);
    this.removeCompanyArrayData = this.removeCompanyArrayData.bind(this);
    this.processingCompanyIndex = this.processingCompanyIndex.bind(this);
    this.hideMessageDialog = this.hideMessageDialog.bind(this);
  }

  async componentDidMount() {
    let session = new SessionHelper();
    var url =
      this.props.baseURL +
      "company/pending-companies?authenticationToken=" +
      session.get("authID");

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.get(url);

    if (apiResponse["data"] === undefined) {
      this.setState({ dataLoaded: true });
    } else {
      let x = [];
      for (var keys in apiResponse["data"]["companies"]) {
        for (var data in apiResponse["data"]["companies"][keys]) {
          if (!x[keys]) x[keys] = [];
          x[keys][data] = apiResponse["data"]["companies"][keys][data];
        }
      }

      this.setState({
        dataLoaded: true,
        status: apiResponse["status"],
        message: apiResponse["message"],
        unverifiedComapniesArray: x
      });
    }
  }

  hideCompanyDetailsDialog() {
    this.setState({ showCompanyDetails: false });
  }

  showCompanyDetailsDialog(companyData) {
    this.setState({ showCompanyDetails: true, companyData: companyData });
  }

  removeCompanyArrayData() {
    const messageTitle = "Verified!";
    const message = (
      <p>
        <b>
          {
            this.state.unverifiedComapniesArray[
              this.state.processingCompanyIndex
            ]["NAME"]
          }
        </b>{" "}
        has been marked as verified company
      </p>
    );

    let x = this.state.unverifiedComapniesArray;
    x.splice(this.state.processingCompanyIndex, 1);

    this.setState({
      unverifiedComapniesArray: x,
      showMessageDialog: true,
      messageDialogTitle: messageTitle,
      messageDialogMessage: message
    });
  }

  processingCompanyIndex(index) {
    this.setState({ processingCompanyIndex: index });
  }

  hideMessageDialog() {
    this.setState({
      showMessageDialog: false,
      messageDialogTitle: null,
      messageDialogMessage: null
    });
  }

  render() {
    let unverifiedCompaniesList = null;
    const showDataTable =
      this.state.dataLoaded === true && this.state.status === 200
        ? true
        : false;

    if (showDataTable) {
      const unverifiedComapniesTableData = Object.values(
        this.state.unverifiedComapniesArray
      ).map((data, index) => {
        let options = {
          day: "numeric",
          month: "2-digit",
          year: "numeric",
          hour: "numeric",
          minute: "numeric"
        };
        let today = new Date(data["CREATED_ON"]);
        return (
          <tr
            key={index}
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.showCompanyDetailsDialog(data);
              this.processingCompanyIndex(index);
            }}
          >
            <td className="align-middle">{data["C_ID"]}</td>
            <td className="align-middle">{data["NAME"]}</td>
            <td className="align-middle">
              <a
                href={"http://" + data["WEBSITE"]}
                target="blank"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {data["WEBSITE"]}
              </a>
            </td>
            <td className="align-middle">{data["EMAIL_ADDRESS"]}</td>
            <td className="align-middle">{data["PHONE_NO"]}</td>
            <td className="align-middle">
              {today.toLocaleDateString("en-IN", options)}
            </td>
            <td style={{ display: "none" }} className="align-middle">
              {data["CREATED_ON"]}
            </td>
          </tr>
        );
      });

      unverifiedCompaniesList = (
        <table
          className="table table-bordered mb-0 table-hover"
          id="dataTable"
          width="100%"
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th>Company ID</th>
              <th>Company Name</th>
              <th>Website</th>
              <th>Email Address</th>
              <th>Phone Number</th>
              <th>Created On</th>
              <th style={{ display: "none" }}>Account Created On</th>
            </tr>
          </thead>

          <tbody>{unverifiedComapniesTableData}</tbody>
        </table>
      );
    } else if (this.state.dataLoaded === false) {
      unverifiedCompaniesList = <p className="mb-0">Loading data...</p>;
    } else {
      unverifiedCompaniesList = (
        <p className="mb-0">There are no unverified comapnies</p>
      );
    }

    return (
      <div className="card mb-3">
        <div className="card-header align-middle">
          <h4>Unverified Companies List</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">{unverifiedCompaniesList}</div>
        </div>
        {this.state.showCompanyDetails ? (
          <CompanyDialog
            baseURL={this.props.baseURL}
            show={this.state.showCompanyDetails}
            hide={this.hideCompanyDetailsDialog}
            companyData={this.state.companyData}
            processArray={this.removeCompanyArrayData}
          />
        ) : null}

        {this.state.showMessageDialog ? (
          <MessageDialog
            show={this.state.showMessageDialog}
            title={this.state.messageDialogTitle}
            message={this.state.messageDialogMessage}
            hideJumboDialog={this.hideMessageDialog}
            buttonName="Ok"
          />
        ) : null}
      </div>
    );
  }
}

export default CompaniesToVerify;
