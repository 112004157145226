import React, { Component } from "react";
import { Collapse, Alert, CustomInput } from "reactstrap";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class UpdateInterview extends Component {
  session = new SessionHelper();

  messageVisibleTimeout = 3000;
  isProcessing = false;
  constructor(props) {
    super(props);

    let interviewData;
    if (this.session.get("userGroup") != "1") {
      props.location.state.interviewData === null &&
        (window.location.href = "/home/interview/list");
      interviewData = props.location.state.interviewData;
    } else {
      interviewData = props.interviewData;
    }
    this.state = {
      intervieweeData: null,
      intervieweeID: interviewData["INTERVIEWEE ID"],
      interviewData: interviewData,
      showEditStatus: "hide",
      showEditCTC: "hide",
      showMessageView: false,
      messageContent: null,
      messageBackground: "danger",
      interviewStatus: interviewData["HIRED"]
    };
    this.editInterviewQualified = this.editInterviewQualified.bind(this);
    this.editCTC = this.editCTC.bind(this);
    this.updateInterviewQualifiedStatus = this.updateInterviewQualifiedStatus.bind(
      this
    );
    this.updateCTC = this.updateCTC.bind(this);
    this.interviewStatusChanged = this.interviewStatusChanged.bind(this);
    this.updateInterviewStatus = this.updateInterviewStatus.bind(this);
  }

  async componentDidMount() {
    var intervieweeDetailsURL =
      this.props.baseURL +
      "interviewee/id/" +
      this.state.intervieweeID +
      "?authenticationToken=" +
      this.session.get("authID");

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.get(intervieweeDetailsURL);
    this.setState({ intervieweeData: apiResponse });
  }

  wordCapitalize(word) {
    let res = "";
    const len = word.length;
    let doCap = true;
    for (let i = 0; i < len; i++) {
      if (word.charAt(i) == " ") {
        doCap = true;
        res += word.charAt(i);
      } else {
        if (doCap) {
          res += word.charAt(i).toUpperCase();
          doCap = false;
        } else {
          res += word.charAt(i).toLowerCase();
        }
      }
    }
    return res;
  }

  processInputChange(inputID, dataArrayID) {
    let x = this.state.interviewData;
    if (inputID === "updatedStage") {
      const x1 = document.getElementById(inputID).value;
      if (x1 > this.state.interviewData["INTERVIEW STAGES"]) {
        x[dataArrayID] = this.state.interviewData["INTERVIEW STAGES"];
      } else if (x1 < 0) {
        x[dataArrayID] = 0;
      } else {
        x[dataArrayID] = document.getElementById(inputID).value;
      }
    } else {
      let x = this.state.interviewData;
      x[dataArrayID] = document.getElementById(inputID).value;
    }
    this.setState({ interviewData: x });
  }

  interviewStatusChanged() {
    const v1 = document.getElementById("hiredStatusSelected").checked;
    const v2 = document.getElementById("hiredStatusNotSelected").checked;
    const v3 = document.getElementById("hiredStatusHold").checked;

    if (v1) {
      this.setState({ interviewStatus: 1 });
    } else if (v2) {
      this.setState({ interviewStatus: 0 });
    } else {
      this.setState({ interviewStatus: null });
    }
  }

  editInterviewQualified() {
    this.setState({ showEditStatus: "show" });
  }

  editCTC() {
    this.setState({ showEditCTC: "show" });
  }

  async updateCTC() {
    if (this.isProcessing) return;
    this.isProcessing = true;
    const updatedCTCValue = document.getElementById("updatedCTC").value;

    const url = this.props.baseURL + "update/interview/ctc";

    let parameters = {
      authenticationToken: this.session.get("authID"),
      intervieweeId: this.state.intervieweeID,
      interviewTime: this.state.interviewData["INTERVIEWED ON"],
      ctcOffered: updatedCTCValue
    };
    if (this.session.get("userGroup") == "1")
      parameters.companyID = this.state.interviewData["COMPANY ID"];

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.post(url, parameters);
    if (
      apiResponse["status"] === 200 &&
      apiResponse["message"] != "No changes made"
    ) {
      const x = this.state.interviewData;
      x["CTC OFFERED"] = updatedCTCValue;
      this.setState({
        showEditCTC: "hide",
        showMessageView: true,
        messageContent: "CTC Value Updated",
        messageBackground: "success",
        interviewData: x
      });
    } else if (
      apiResponse["status"] === 200 &&
      apiResponse["message"] === "No changes made"
    ) {
      this.setState({
        showEditCTC: "hide",
        showMessageView: true,
        messageContent: apiResponse["message"],
        messageBackground: "warning"
      });
    } else if (apiResponse["error"] === true) {
      this.isProcessing = false;
      this.setState({
        showEditCTC: "hide",
        showMessageView: true,
        messageContent: apiResponse["message"],
        messageBackground: "danger"
      });
    } else {
      this.setState({
        showEditCTC: "hide",
        showMessageView: true,
        messageContent: apiResponse["message"],
        messageBackground: "danger"
      });
    }
    this.isProcessing = false;
    setTimeout(() => {
      this.setState({
        showMessageView: false
      });
    }, this.messageVisibleTimeout);
  }

  async updateInterviewQualifiedStatus() {
    if (this.isProcessing) return;
    this.isProcessing = true;
    const url = this.props.baseURL + "update/interview/status";
    const updatedQualifiedStageValue = document.getElementById("updatedStage")
      .value;

    let parameters = {
      authenticationToken: this.session.get("authID"),
      intervieweeId: this.state.intervieweeID,
      interviewTime: this.state.interviewData["INTERVIEWED ON"],
      interviewStatus: updatedQualifiedStageValue
    };
    if (this.session.get("userGroup") == "1")
      parameters.companyID = this.state.interviewData["COMPANY ID"];

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.post(url, parameters);
    if (
      apiResponse["status"] === 200 &&
      apiResponse["message"] != "No changes made"
    ) {
      this.setState({
        showEditStatus: "hide",
        showMessageView: true,
        messageContent: apiResponse["message"],
        messageBackground: "success"
      });
    } else if (
      apiResponse["status"] === 200 &&
      apiResponse["message"] === "No changes made"
    ) {
      this.setState({
        showEditStatus: "hide",
        showMessageView: true,
        messageContent: apiResponse["message"],
        messageBackground: "warning"
      });
    } else if (apiResponse["error"]) {
      this.setState({
        showEditStatus: "hide",
        showMessageView: true,
        messageContent: apiResponse["message"],
        messageBackground: "danger"
      });
    } else {
      this.setState({
        showEditStatus: "hide",
        showMessageView: true,
        messageContent: apiResponse["message"],
        messageBackground: "danger"
      });
    }
    this.isProcessing = false;

    setTimeout(() => {
      this.setState({
        showMessageView: false
      });
    }, this.messageVisibleTimeout);
  }

  async updateInterviewStatus() {
    if (this.isProcessing) return;
    this.isProcessing = true;

    const url = this.props.baseURL + "update/interview/ishired";

    let parameters = {
      authenticationToken: this.session.get("authID"),
      intervieweeId: this.state.intervieweeID,
      interviewTime: this.state.interviewData["INTERVIEWED ON"],
      hired: this.state.interviewStatus
    };
    if (this.session.get("userGroup") == "1")
      parameters.companyID = this.state.interviewData["COMPANY ID"];

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.post(url, parameters);

    if (
      apiResponse["status"] === 200 &&
      apiResponse["message"] != "No changes made"
    ) {
      const x = this.state.interviewData;
      x["HIRED"] = this.state.interviewStatus;
      this.setState({
        showEditStatus: "hide",
        showMessageView: true,
        messageContent: apiResponse["message"],
        messageBackground: "success",
        interviewData: x
      });
    } else if (
      apiResponse["status"] === 200 &&
      apiResponse["message"] === "No changes made"
    ) {
      this.setState({
        showEditStatus: "hide",
        showMessageView: true,
        messageContent: apiResponse["message"],
        messageBackground: "warning"
      });
    } else if (apiResponse["error"]) {
      this.setState({
        showEditStatus: "hide",
        showMessageView: true,
        messageContent: apiResponse["message"],
        messageBackground: "danger"
      });
    } else {
      this.setState({
        showEditStatus: "hide",
        showMessageView: true,
        messageContent: apiResponse["message"],
        messageBackground: "danger"
      });
    }
    this.isProcessing = false;

    setTimeout(() => {
      this.setState({
        showMessageView: false
      });
    }, this.messageVisibleTimeout);
  }

  render() {
    let intervieweeID, idType, intervieweeName, emailAddress, phoneNumber;
    let interviewDataTable;
    const userPhotoURL =
      this.props.baseURL + "user/photo?userID=" + this.state.intervieweeID;

    if (
      this.state.intervieweeData != null &&
      this.state.intervieweeData["status"] == 200 &&
      this.state.intervieweeData["data"] != null
    ) {
      intervieweeID = this.state.intervieweeID;
      idType = this.state.intervieweeData["data"]["user"]["ID_TYPE"];
      intervieweeName = this.state.intervieweeData["data"]["user"]["NAME"];
      emailAddress = this.state.intervieweeData["data"]["user"]["EMAIL_ID"];
      phoneNumber = this.state.intervieweeData["data"]["user"]["PHONE_NO"];
    }

    if (this.state.interviewData != null) {
      const d2 = (
        <tbody>
          <tr>
            <td>{this.state.interviewData["INTERVIEWEE ID"]}</td>
            <td>{this.state.interviewData["POSITION"]}</td>
            <td>
              <span
                style={{
                  display:
                    this.state.showEditStatus == "show" ? "inline" : "none"
                }}
              >
                <input
                  type="number"
                  className="form-control"
                  style={{ height: "100%" }}
                  min="0"
                  max={this.state.interviewData["INTERVIEW STAGES"]}
                  id="updatedStage"
                  onChange={() =>
                    this.processInputChange("updatedStage", "STATUS")
                  }
                  value={this.state.interviewData["STATUS"]}
                />
              </span>
              <span
                style={{
                  display:
                    this.state.showEditStatus == "show" ? "none" : "inline"
                }}
              >
                {this.state.interviewData["STATUS"] === null
                  ? "0"
                  : this.state.interviewData["STATUS"]}
              </span>
            </td>

            <td>{this.state.interviewData["INTERVIEW STAGES"]}</td>

            {"CTC OFFERED" in this.state.interviewData ? (
              <td>
                <span
                  style={{
                    display:
                      this.state.showEditCTC == "show" ? "inline" : "none"
                  }}
                >
                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    id="updatedCTC"
                    onChange={() =>
                      this.processInputChange("updatedCTC", "CTC OFFERED")
                    }
                    value={this.state.interviewData["CTC OFFERED"]}
                  />
                </span>
                <span
                  style={{
                    display:
                      this.state.showEditCTC == "show" ? "none" : "inline"
                  }}
                >
                  {this.state.interviewData["CTC OFFERED"] ? (
                    this.state.interviewData["CTC OFFERED"]
                  ) : (
                    <span className="small text-muted">
                      -- Not Specified --
                    </span>
                  )}
                </span>
              </td>
            ) : null}

            <td>{this.state.interviewData["INTERVIEWED ON"]}</td>
          </tr>
        </tbody>
      );

      interviewDataTable = (
        <div>
          <table className="table table-bordered mb-0" cellSpacing="0">
            <thead>
              <tr>
                <th>Interviewee ID</th>
                <th>For Position</th>
                <th>
                  Interview Stages Qualified
                  <span
                    id="spanStagesQualifiedIcon"
                    className="align-middle"
                    style={{
                      display:
                        this.state.showEditStatus == "show" ? "none" : "inline"
                    }}
                  >
                    <i
                      className="fas fa-pencil-alt ml-1 mt-1 icon"
                      style={{ float: "right" }}
                      title="Edit"
                      onClick={this.editInterviewQualified}
                    />
                  </span>
                  <span
                    className="align-middle"
                    style={{
                      display:
                        this.state.showEditStatus == "show" ? "inline" : "none"
                    }}
                  >
                    <i
                      className="fas fa-cloud-upload-alt ml-1 mt-1 icon"
                      style={{ float: "right" }}
                      title="Update Stages Qualified"
                      onClick={this.updateInterviewQualifiedStatus}
                    />
                  </span>
                </th>
                <th>Total Interview Stages</th>
                {"CTC OFFERED" in this.state.interviewData ? (
                  <th>
                    CTC Offered
                    <span
                      className="align-middle"
                      style={{
                        display:
                          this.state.showEditCTC == "show" ? "none" : "inline"
                      }}
                    >
                      <i
                        className="fas fa-pencil-alt ml-1 mt-1 icon"
                        style={{ float: "right" }}
                        title="Edit"
                        onClick={this.editCTC}
                      />
                    </span>
                    <span
                      className="align-middle"
                      style={{
                        display:
                          this.state.showEditCTC == "show" ? "inline" : "none"
                      }}
                    >
                      <i
                        className="fas fa-cloud-upload-alt ml-1 mt-1 icon"
                        style={{ float: "right" }}
                        title="Update CTC"
                        onClick={this.updateCTC}
                      />
                    </span>
                  </th>
                ) : null}

                <th>Interviewed On</th>
              </tr>
            </thead>
            {d2}
          </table>
          <div className="mt-3 mx-3">
            <label>
              <b>Interview Status</b>
              <span
                className="align-middle"
                style={{
                  display:
                    this.state.interviewData["HIRED"] !=
                    this.state.interviewStatus
                      ? "inline"
                      : "none"
                }}
                onClick={this.updateInterviewStatus}
              >
                <i
                  className="fas fa-cloud-upload-alt ml-2 mt-1 icon"
                  title="Update Interview Status"
                />
              </span>
            </label>
            <CustomInput
              type="radio"
              id="hiredStatusSelected"
              name="customRadio"
              label="Selected"
              checked={this.state.interviewStatus == "1" ? true : null}
              onChange={this.interviewStatusChanged}
            />
            <CustomInput
              type="radio"
              id="hiredStatusNotSelected"
              name="customRadio"
              label="Not Selected"
              checked={this.state.interviewStatus == "0" ? true : null}
              onChange={this.interviewStatusChanged}
            />
            <CustomInput
              type="radio"
              id="hiredStatusHold"
              name="customRadio"
              label="On Hold"
              checked={this.state.interviewStatus == undefined ? true : null}
              onChange={this.interviewStatusChanged}
            />
          </div>
        </div>
      );
    } else {
      interviewDataTable = <p className="ml-2 mb-0">No records found!</p>;
    }

    intervieweeID = intervieweeID != null ? intervieweeID : "---";
    idType = idType != null ? this.wordCapitalize(idType) : "Id Type";
    intervieweeName =
      intervieweeName != null ? this.wordCapitalize(intervieweeName) : "---";
    emailAddress = emailAddress != null ? emailAddress : "---";
    phoneNumber = phoneNumber != null ? phoneNumber : "---";

    return (
      <React.Fragment>
        <div className="card mb-3">
          <div className="card-header">
            <h4>Update Interview</h4>
          </div>
          <div className="card-body">
            <div
              className="row"
              style={{
                maxWidth: "600px",
                margin: "auto",
                marginLeft: "1%"
              }}
            >
              <div className="col-md-4 col-sm-12 mb-2">
                <img
                  style={{
                    maxWidth: "140px",
                    maxHeight: "140px",
                    minHeight: "140px",
                    minWidth: "140px",
                    objectFit: "cover"
                  }}
                  src={userPhotoURL}
                  className="border rounded-circle"
                />
              </div>
              <div className="col-md-8 col-sm-12">
                {intervieweeName}
                <br />
                {idType} : {intervieweeID}
                <br />
                {emailAddress}
                <br />
                {phoneNumber}
                <br />
                <a href="#">
                  <u>Resume</u>
                </a>
              </div>
            </div>
            <br />
            <div className="table-responsive mb-3">
              <h5 className="ml-2 mt-2">Interview Data</h5>
              {interviewDataTable}
            </div>
          </div>
          <Collapse isOpen={this.state.showMessageView}>
            <Alert
              color={this.state.messageBackground}
              className="rounded-0 mb-0"
            >
              {this.state.messageContent}
            </Alert>
          </Collapse>
        </div>
      </React.Fragment>
    );
  }
}

export default UpdateInterview;
