import React, { Component } from "react";
import CompaniesToVerify from "./CompaniesToVerify";
import RestAPIHelper from "../../RestAPIHelper";
import { Link } from "react-router-dom";
import SessionHelper from "../../SessionHelper";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    let x = [];
    x["TOTAL_INTERVIEWS"] = 0;
    x["PENDING_INTERVIEWS"] = 0;
    x["REGISTERED_COMPANIES"] = 0;
    x["UNVERIFIED_COMPANIES"] = 0;
    this.state = { dashboardData: x };
  }

  async componentDidMount() {
    let session = new SessionHelper();

    var url =
      this.props.baseURL +
      "data/dashboard?authenticationToken=" +
      session.get("authID");

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.get(url);

    if (apiResponse["status"] === 200) {
      this.setState({
        dataLoaded: true,
        dashboardData: apiResponse["data"]["dashboard data"]
      });
    } else {
      this.setState({ dataLoaded: true });
    }

    const script1 = document.createElement("script");
    script1.src = "/assets/js/othercharts.js";
    script1.id = "othercharts";
    document.body.replaceChild(script1, document.getElementById(script1.id));
  }

  render() {
    return (
      <React.Fragment>
        {/* <div className="row pr-3">
          <div className="col-xl-3 col-sm-6 pr-0 mb-3">
            <div className="card text-white bg-primary o-hidden h-100">
              <div className="card-body">
                <div className="card-body-icon">
                  <i className="fas fa-fw fa-user-circle" />
                </div>
                <div className="mr-5">
                  {this.state.dashboardData["TOTAL_INTERVIEWS"]} Total
                  Interviewes!
                </div>
              </div>
              <Link
                className="card-footer text-white clearfix small z-1"
                to="/super-admin/interviewlist"
              >
                <span className="float-left">View Details</span>
                <span className="float-right">
                  <i className="fas fa-angle-right" />
                </span>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 pr-0 mb-3">
            <div className="card text-white bg-warning o-hidden h-100">
              <div className="card-body">
                <div className="card-body-icon">
                  <i className="fas fa-fw fa-list" />
                </div>
                <div className="mr-5">
                  {this.state.dashboardData["PENDING_INTERVIEWS"]} Pending
                  Interviews
                </div>
              </div>
              <Link
                className="card-footer text-white clearfix small z-1"
                to="/super-admin/interviewlist?filter=on+hold"
              >
                <span className="float-left">View Details</span>
                <span className="float-right">
                  <i className="fas fa-angle-right" />
                </span>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 pr-0 mb-3">
            <div className="card text-white bg-success o-hidden h-100">
              <div className="card-body">
                <div className="card-body-icon">
                  <i className="fas fa-fw  fa-user-circle" />
                </div>
                <div className="mr-5">
                  {this.state.dashboardData["REGISTERED_COMPANIES"]} Registered
                  Companies
                </div>
              </div>
              <Link
                className="card-footer text-white clearfix small z-1"
                to="/super-admin/companieslist/verified"
              >
                <span className="float-left">View Details</span>
                <span className="float-right">
                  <i className="fas fa-angle-right" />
                </span>
              </Link>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 pr-0 mb-3">
            <div className="card text-white bg-danger o-hidden h-100">
              <div className="card-body">
                <div className="card-body-icon">
                  <i className="fas fa-fw fa-question" />
                </div>
                <div className="mr-5">
                  {this.state.dashboardData["UNVERIFIED_COMPANIES"]} Unverified
                  Companies
                </div>
              </div>
              <Link
                className="card-footer text-white clearfix small z-1"
                to="/super-admin/companieslist/unverified"
              >
                <span className="float-left">View Details</span>
                <span className="float-right">
                  <i className="fas fa-angle-right" />
                </span>
              </Link>
            </div>
          </div>
        </div> */}

        <div className="page-header">
          <h4 className="page-title">Dashboard</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#" className="text-light-color">
                Home
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Dashboard
            </li>
          </ol>
        </div>

        <div className="row row-deck">
          <div className="col-sm-12 col-lg-6 col-xl-3 col-md-6">
            <div className="card text-center">
              <div className="card-body  mb-0">
                <h5 className="mb-3">Total Interviews</h5>
                <input
                  type="text"
                  className="knob mb-0"
                  value="80"
                  data-thickness="0.2"
                  data-width="90"
                  data-height="90"
                  data-fgColor="#7673e6"
                />
                <br />
                <span className="text-muted mt-3">
                  <i
                    className="fa fa-check-circle mr-2"
                    style={{ color: "#7673e6" }}
                  />
                  {this.state.dashboardData["TOTAL_INTERVIEWS"]} Total
                  Interviewes
                </span>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-lg-6 col-xl-3 col-md-6">
            <div className="card text-center">
              <div className="card-body  mb-0">
                <h5 className="mb-3">Ongoing Interviews</h5>
                <input
                  type="text"
                  className="knob mb-0"
                  value="53"
                  data-thickness="0.2"
                  data-width="90"
                  data-height="90"
                  data-fgColor="#22e840"
                />
                <br />
                <span className="text-muted mt-3">
                  <i
                    className="fa fa-check-circle mr-2"
                    style={{ color: "#22e840" }}
                  />
                  53% On Going
                </span>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-lg-6 col-xl-3 col-md-6">
            <div className="card text-center">
              <div className="card-body  mb-0">
                <h5 className="mb-3">Pending Interviews</h5>
                <input
                  type="text"
                  className="knob mb-0"
                  value="74"
                  data-thickness="0.2"
                  data-width="90"
                  data-height="90"
                  data-fgColor="#ffb209"
                />
                <br />
                <span className="text-muted mt-3">
                  <i
                    className="fa fa-check-circle mr-2"
                    style={{ color: "#ffb209" }}
                  />
                  {this.state.dashboardData["PENDING_INTERVIEWS"]} Pending
                  Interviews
                </span>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-lg-6 col-xl-3 col-md-6">
            <div className="card text-center ">
              <div className="card-body  mb-0">
                <h5 className="mb-3">Deactivated Accounts</h5>
                <div className="widget-3 mb-1">
                  <i className="icon icon-trash text-orange" />
                  <br />
                </div>
                <span className="text-muted mt-5">58 Deleted</span>
              </div>
            </div>
          </div>
        </div>

        <CompaniesToVerify baseURL={this.props.baseURL} />
      </React.Fragment>
    );
  }
}

export default Dashboard;
