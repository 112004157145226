import React, { Component } from "react";
import LoadingModal from "../dialogs/LoadingModal";
import MessageDialog from "../dialogs/MessageDialog";
import { Alert, Collapse } from "reactstrap";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class AddEmployee extends Component {
  idEmployeeID = "employeeID";
  idEmployeeIDType = "employeeIDType";
  idEmployeeName = "employeeName";
  idEmployeeEmailID = "employeeEmailID";
  idEmployeePhoneNumber = "employeePhoneNo";
  idPassword = "employeePassword";
  idRePassword = "confirmPassword";
  idEmployeePermission = "employeePermissions";
  idEmployeePhoto = "employeePhoto";
  idEmployeePhotoLabel = "employeePhotoLabel";
  idAuthToken = "authenticationToken";

  employeeIDType = "";
  employeePermission = "000000";

  constructor(props) {
    super(props);
    this.state = {
      errorStatus: null,
      showError: false,
      errorMessage: null,
      isloading: false
    };

    this.textInput = React.createRef();
    this.focus = this.focus.bind(this);

    this.clearErrorViews = this.clearErrorViews.bind(this);
    this.setIDType = this.setIDType.bind(this);
    this.setPermission = this.setPermission.bind(this);
    this.displaySelectedFIleName = this.displaySelectedFIleName.bind(this);
    this.createEmployee = this.createEmployee.bind(this);
  }

  focus() {
    if (this.textInput.current !== null) this.textInput.current.focus();
  }

  setIDType(idtype) {
    this.employeeIDType = idtype;
    document.getElementById("idtypeButton").innerHTML = this.employeeIDType;
  }

  clearErrorViews() {
    this.setState({ errorStatus: null, showError: false, errorMessage: null });
  }

  setPermission(id, index) {
    index = parseInt(index);
    let replacement = document.getElementById(id).checked == true ? "1" : "0";

    this.employeePermission =
      this.employeePermission.substr(0, index) +
      replacement +
      this.employeePermission.substr(index + 1);
  }

  displaySelectedFIleName(id, label, defaultMsg) {
    const fileName = document.getElementById(id).value;
    if (fileName) {
      document.getElementById(label).innerHTML = fileName.substr(
        fileName.lastIndexOf("\\") + 1
      );
    } else {
      document.getElementById(label).innerHTML = defaultMsg;
    }
  }

  async createEmployee() {
    let session = new SessionHelper();
    this.clearErrorViews();
    this.setState({ isloading: true });

    const employeeName = document.getElementById(this.idEmployeeName).value;
    const employeeID = document.getElementById(this.idEmployeeID).value;
    const employeeIDType = this.employeeIDType;
    const employeeEmailID = document.getElementById(this.idEmployeeEmailID)
      .value;
    const employeePhoneNo = document.getElementById(this.idEmployeePhoneNumber)
      .value;
    const password = document.getElementById(this.idPassword).value;
    const rePassword = document.getElementById(this.idRePassword).value;
    const employeePhoto = document.getElementById(this.idEmployeePhoto)
      .files[0];
    const employeePermission = this.employeePermission;
    const authToken = session.get("authID");

    if (!employeeName) {
      this.setState({
        showError: true,
        errorStatus: 205,
        errorMessage: "Please enter employee name"
      });
    } else if (!employeeID) {
      this.setState({
        showError: true,
        errorStatus: 201,
        errorMessage: "Please enter employee ID"
      });
    } else if (!employeeIDType) {
      this.setState({
        showError: true,
        errorStatus: 202,
        errorMessage: "Please select a valid employee ID type"
      });
    } else if (!employeeEmailID) {
      this.setState({
        showError: true,
        errorStatus: 206,
        errorMessage: "Please enter employee employee address"
      });
    } else if (!employeePhoneNo) {
      this.setState({
        showError: true,
        errorStatus: 219,
        errorMessage: "Please enter employee phone number"
      });
    } else if (!password) {
      this.setState({
        showError: true,
        errorStatus: 208,
        errorMessage: "Please enter account password"
      });
    } else if (password !== rePassword) {
      this.setState({
        showError: true,
        errorStatus: 208.1,
        errorMessage: "Entered password and retype password does not match"
      });
    } else if (employeePhoto["size"] > 5242880) {
      this.setState({
        showError: true,
        errorStatus: 217,
        errorMessage: "Employee photo size should be less than 5MB"
      });
    } else {
      let parameters = {
        employeeID: employeeID,
        employeeIDType: employeeIDType,
        employeeName: employeeName,
        employeeEmailID: employeeEmailID,
        employeePhoneNo: employeePhoneNo,
        employeePassword: password,
        employeePhoto: employeePhoto,
        employeePermissions: employeePermission,
        authenticationToken: authToken
      };

      let apiHelper = new RestAPIHelper();
      const apiResponse = await apiHelper.post(
        this.props.baseURL + "add/employee",
        parameters
      );

      if (apiResponse["status"] == 200) {
        const dialogTitle = "Employee Created";
        const dialogMessage =
          "Sub Employee account has been created. Now they can login with this credentials.";
        this.setState({
          showJumboDialog: true,
          jumboDialogTitle: dialogTitle,
          jumboDialogMessage: dialogMessage
        });
      } else {
        if (apiResponse["status"] == 202)
          apiResponse["message"] = "Please select a valid Employee ID Type";
        this.setState({
          showError: true,
          errorStatus: apiResponse["status"],
          errorMessage: apiResponse["message"]
        });
      }
    }
    this.setState({ isloading: false });
  }

  componentDidUpdate() {
    this.focus();
  }

  render() {
    return (
      <div>
        <div className="card mb-3">
          <div className="card-header">
            <h4>Add New Employee</h4>
          </div>
          <Collapse
            isOpen={
              (this.state.errorStatus === 202 ||
                this.state.errorStatus === 204 ||
                this.state.errorStatus === 209 ||
                this.state.errorStatus === 212 ||
                this.state.errorStatus === 213 ||
                this.state.errorStatus === 214 ||
                this.state.errorStatus === 215 ||
                this.state.errorStatus === 216 ||
                this.state.errorStatus === 217 ||
                this.state.errorStatus === 218) &&
              this.state.showError
                ? true
                : false
            }
          >
            <Alert color="danger" className="rounded-0">
              {this.state.errorMessage}
            </Alert>
          </Collapse>

          <div className="card-body">
            <form
              className="container-fluid needs-validation"
              noValidate
              style={{ maxWidth: "600px" }}
            >
              <div className="form-row">
                <div className="col-md-12">
                  <div className="form-label-group">
                    <input
                      ref={
                        this.state.errorStatus === 205 && this.state.showError
                          ? this.textInput
                          : null
                      }
                      type="text"
                      onChange={this.clearErrorViews}
                      id={this.idEmployeeName}
                      className={
                        this.state.errorStatus === 205 && this.state.showError
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Employee Name"
                    />
                    <div className="invalid-feedback">
                      {this.state.errorMessage}
                    </div>
                    <label htmlFor={this.idEmployeeName}>
                      Employee Name
                      <font color="red">
                        <sup>*</sup>
                      </font>
                    </label>
                  </div>
                </div>
              </div>
              <br />

              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-8">
                    <div className="form-label-group">
                      <input
                        ref={
                          (this.state.errorStatus === 201 ||
                            this.state.errorStatus === 203 ||
                            this.state.errorStatus === 210) &&
                          this.state.showError
                            ? this.textInput
                            : null
                        }
                        type="text"
                        onChange={this.clearErrorViews}
                        id={this.idEmployeeID}
                        className={
                          (this.state.errorStatus === 201 ||
                            this.state.errorStatus === 203 ||
                            this.state.errorStatus === 210) &&
                          this.state.showError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Employee ID"
                        required="required"
                      />
                      <div className="invalid-feedback">
                        {this.state.errorMessage}
                      </div>
                      <label htmlFor={this.idEmployeeID}>
                        Employee ID
                        <font color="red">
                          <sup>*</sup>
                        </font>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-label-group">
                      <div className="dropdown">
                        <button
                          className="btn btn-primary dropdown-toggle"
                          type="button"
                          style={{
                            minHeight: "43px",
                            float: "right"
                          }}
                          data-toggle="dropdown"
                        >
                          <label
                            id="idtypeButton"
                            className="m-0"
                            style={{ color: "white" }}
                          >
                            Select ID Type
                          </label>
                          <span className="caret mx-1" />
                        </button>
                        <ul className="dropdown-menu">
                          <li
                            className="px-2 pb-2 border-bottom"
                            style={{ cursor: "pointer" }}
                          >
                            <a onClick={() => this.setIDType("AADHAR NUMBER")}>
                              AADHAR NUMBER
                            </a>
                          </li>
                          <li className="p-2 border-bottom">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => this.setIDType("DRIVING LICENCE")}
                            >
                              DRIVING LICENCE
                            </a>
                          </li>
                          <li
                            className="px-2 pt-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.setIDType("PASSPORT NUMBER")}
                          >
                            <a
                              onClick={() => this.setIDType("PASSPORT NUMBER")}
                            >
                              PASSPORT NUMBER
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12">
                  <div className="form-label-group">
                    <input
                      ref={
                        (this.state.errorStatus === 206 ||
                          this.state.errorStatus === 207 ||
                          this.state.errorStatus === 211) &&
                        this.state.showError
                          ? this.textInput
                          : null
                      }
                      type="email"
                      onChange={this.clearErrorViews}
                      id={this.idEmployeeEmailID}
                      className={
                        (this.state.errorStatus === 206 ||
                          this.state.errorStatus === 207 ||
                          this.state.errorStatus === 211) &&
                        this.state.showError
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Employee Email ID"
                      required="required"
                    />
                    <div className="invalid-feedback">
                      {this.state.errorMessage}
                    </div>
                    <label htmlFor={this.idEmployeeEmailID}>
                      Employee Email ID
                      <font color="red">
                        <sup>*</sup>
                      </font>
                    </label>
                  </div>
                </div>
              </div>
              <br />

              <div className="form-row">
                <div className="col-md-12">
                  <div className="form-label-group">
                    <input
                      ref={
                        this.state.errorStatus === 219 && this.state.showError
                          ? this.textInput
                          : null
                      }
                      type="text"
                      id={this.idEmployeePhoneNumber}
                      className={
                        this.state.errorStatus === 219 && this.state.showError
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      placeholder="Employee Phone Number"
                      required="required"
                    />
                    <div className="invalid-feedback">
                      {this.state.errorMessage}
                    </div>
                    <label htmlFor={this.idEmployeePhoneNumber}>
                      Employee Phone Number
                    </label>
                  </div>
                </div>
              </div>
              <br />

              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-label-group">
                      <input
                        ref={
                          this.state.errorStatus === 208 && this.state.showError
                            ? this.textInput
                            : null
                        }
                        type="password"
                        onChange={this.clearErrorViews}
                        id={this.idPassword}
                        className={
                          this.state.errorStatus === 208 && this.state.showError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Password"
                        required="required"
                      />
                      <div className="invalid-feedback">
                        {this.state.errorMessage}
                      </div>
                      <label htmlFor={this.idPassword}>
                        Password
                        <font color="red">
                          <sup>*</sup>
                        </font>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-label-group">
                      <input
                        ref={
                          this.state.errorStatus === 208.1 &&
                          this.state.showError
                            ? this.textInput
                            : null
                        }
                        type="password"
                        id={this.idRePassword}
                        className={
                          this.state.errorStatus === 208.1 &&
                          this.state.showError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Confirm password"
                        required="required"
                      />
                      <div className="invalid-feedback">
                        {this.state.errorMessage}
                      </div>
                      <label htmlFor={this.idRePassword}>
                        Confirm password
                        <font color="red">
                          <sup>*</sup>
                        </font>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="input-group mb-3">
                <div className="custom-file">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={() =>
                      this.displaySelectedFIleName(
                        this.idEmployeePhoto,
                        this.idEmployeePhotoLabel,
                        "Select Employee Photo"
                      )
                    }
                    className="custom-file-input"
                    id={this.idEmployeePhoto}
                  />
                  <label
                    id={this.idEmployeePhotoLabel}
                    className="custom-file-label"
                    htmlFor="inputGroupFile01"
                  >
                    Select Employee Photo
                  </label>
                </div>
              </div>

              <div className="border rounded p-3">
                <label className="m-0">
                  <font size="4">Previleges</font>
                </label>
                <hr className="my-3" />
                <div className="form-row">
                  <div className="col-md-6 p-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        onClick={() => this.setPermission("p1", 1)}
                        id="p1"
                      />
                      <label className="custom-control-label" htmlFor="p1">
                        Can Create Sub Employee's
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        onClick={() => this.setPermission("p2", 2)}
                        id="p2"
                      />
                      <label className="custom-control-label" htmlFor="p2">
                        Add Interview
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 p-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        onClick={() => this.setPermission("p3", 3)}
                        id="p3"
                      />
                      <label className="custom-control-label" htmlFor="p3">
                        Can View CTC
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        onClick={() => this.setPermission("p4", 4)}
                        id="p4"
                      />
                      <label className="custom-control-label" htmlFor="p4">
                        Can View Only In Mobile
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 p-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        onClick={() => this.setPermission("p5", "5")}
                        id="p5"
                      />
                      <label className="custom-control-label" htmlFor="p5">
                        Can Scan Biometrics
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <center>
                <a
                  className="btn btn-primary btn-block"
                  style={{ maxWidth: "50%" }}
                  onClick={this.createEmployee}
                  href="#"
                >
                  Create Employee
                </a>
              </center>
            </form>
          </div>
          {this.state.isloading && (
            <LoadingModal
              show={this.state.isloading}
              message="Proceesing your data. Please Wait..."
            />
          )}

          <MessageDialog
            show={this.state.showJumboDialog}
            title={this.state.jumboDialogTitle}
            message={this.state.jumboDialogMessage}
            hideJumboDialog={() => {
              this.props.history.push("/home/dashboard");
            }}
            buttonName="Done"
          />
        </div>
      </div>
    );
  }
}

export default AddEmployee;
