import React, { Component } from "react";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class ActivityLog extends Component {
  constructor(props) {
    super(props);
    this.state = { dataLoaded: false };
  }

  async componentDidMount() {
    let session = new SessionHelper();
    var url = this.props.baseURL + "users/activitylog";
    let parameters = {
      authenticationToken: session.get("authID")
    };
    if (this.props.companyID !== undefined)
      parameters.companyID = this.props.companyID;

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.post(url, parameters);
    if (apiResponse["data"] === undefined) {
      this.setState({ dataLoaded: true });
    } else {
      let x = [];
      let counter = 0;
      for (var keys in apiResponse["data"]["Activity Log List"]) {
        if (!x[counter]) x[counter] = [];
        for (var data in apiResponse["data"]["Activity Log List"][keys]) {
          x[counter][data] =
            apiResponse["data"]["Activity Log List"][keys][data];
        }
        counter++;
      }

      this.setState({
        dataLoaded: true,
        status: apiResponse["status"],
        message: apiResponse["message"],
        activityLogData: x
      });
    }
  }

  render() {
    let negativeCreditsActionCodes = ["VIEW_INT_DET"];
    let positiveCreditsActionCodes = ["ADD_INTERVIEW"];
    let activityList;
    if (this.state.dataLoaded === true && this.state.activityLogData != null) {
      const activityLogTableData = Object.values(
        this.state.activityLogData
      ).map((data, index) => {
        return (
          <tr key={index}>
            <td>{data["USER_ID"]}</td>
            <td>{data["DESCRIPTION"]}</td>
            <td>{data["SUBJECT_ID"] ? data["SUBJECT_ID"] : "---"}</td>
            <td>
              {new Date(data["PERFORMED_ON"]).toLocaleDateString("en-IN", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "numeric",
                minute: "numeric"
              })}
            </td>
            <td>
              {negativeCreditsActionCodes.includes(data["ACTION_CODE"]) ? (
                <font color="red">
                  <b>-{data["CREDITS"]}</b>
                </font>
              ) : positiveCreditsActionCodes.includes(data["ACTION_CODE"]) ? (
                <font color="green">
                  <b>+{data["CREDITS"]}</b>
                </font>
              ) : (
                data["CREDITS"]
              )}
            </td>
            <td style={{ display: "none" }}>{data["PERFORMED_ON"]}</td>
          </tr>
        );
      });

      activityList = (
        <table
          className="table table-bordered mb-0"
          id="dataTable"
          width="100%"
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th>User</th>
              <th>Action</th>
              <th>Subject ID</th>
              <th>Performed On</th>
              <th>Credits</th>
              <th style={{ display: "none" }}>Time</th>
            </tr>
          </thead>
          <tbody>{activityLogTableData}</tbody>
        </table>
      );
    } else if (this.state.dataLoaded === false) {
      activityList = "Loading Data. Please wait...";
    }
    return (
      <React.Fragment>
        <div className="card mb-3">
          <div className="card-header">
            <h4>Activity Log</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">{activityList}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ActivityLog;
