import React, { Component } from "react";
import queryString from "query-string";
import LoadingModal from "../dialogs/LoadingModal";
import MessageDialog from "../dialogs/MessageDialog";
import { Collapse, Alert } from "reactstrap";
import RestAPIHelper from "../../RestAPIHelper";

class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    let params = queryString.parse(this.props.location.search);
    this.state = { id: parseInt(params.id) };

    this.updatePassword = this.updatePassword.bind(this);
    this.navigateToLoginPage = this.navigateToLoginPage.bind(this);
  }

  navigateToLoginPage() {
    window.location.href = "/login";
    this.setState({
      showErrorMessage: false
    });
  }

  async updatePassword() {
    this.setState({
      isloading: true,
      showErrorMessage: false,
      errorMessage: null
    });
    const p1 = document.getElementById("password1").value;
    const p2 = document.getElementById("password2").value;
    if (p1.length > 5 && p1.length < 20 && p1 === p2) {
      const url = this.props.baseURL + "update/password";

      let parameters = {
        id: this.state.id,
        password: p1
      };

      let apiHelper = new RestAPIHelper();
      const apiResponse = await apiHelper.post(url, parameters);

      if (apiResponse["status"] == 200) {
        const title = "Password Updated";
        const message =
          "Your account password has been updated. Now you can login with your new password.";
        this.setState({
          isloading: false,
          showMessageDialog: true,
          messageDialogTitle: title,
          messageDialogMessage: message
        });
      } else {
        this.setState({
          isloading: false,
          showErrorMessage: true,
          errorMessage: apiResponse["message"]
        });
      }
    } else if (p1.length < 5 || p1.length > 20) {
      this.setState({
        isloading: false,
        showErrorMessage: true,
        errorMessage:
          "Invalid password. Password length should be greater than 5 and less than 20 characters"
      });
    } else if (p1 !== p2) {
      this.setState({
        isloading: false,
        showErrorMessage: true,
        errorMessage: "Password and Retype password are not matching"
      });
    }
  }

  render() {
    if (this.state.id && this.state.id !== NaN) {
      return (
        <div className="container">
          <div align="center" className="col-md-12 col-sm-12">
            <img
              style={{ maxHeight: "100px", marginTop: "5%" }}
              src="img/logo.SVG"
            />
          </div>

          <div className="card card-register mx-auto mt-5">
            <div className="card-header">Update Password</div>
            <Collapse isOpen={this.state.showErrorMessage}>
              <Alert color="danger" className="rounded-0 mb-0">
                {this.state.errorMessage}
              </Alert>
            </Collapse>
            <div className="card-body">
              <form>
                <div className="form-group">
                  <div className="form-label-group mb-3">
                    <input
                      type="text"
                      id="password1"
                      className="form-control"
                      placeholder="Enter your New Password"
                      required="required"
                      autoFocus="autofocus"
                    />
                    <label htmlFor="password1">Enter your New Password</label>
                  </div>
                  <div className="form-label-group">
                    <input
                      type="password"
                      id="password2"
                      className="form-control"
                      placeholder="Retype New Password"
                      required="required"
                      autoFocus="autofocus"
                    />
                    <label htmlFor="password2">Retype New Password</label>
                  </div>
                </div>
                <a
                  className="btn btn-primary btn-block mx-auto"
                  href="#"
                  onClick={this.updatePassword}
                >
                  Update Password
                </a>
              </form>
              <hr />
              <div className="text-center">
                <a className="d-block small mt-3" href="/signup">
                  Register an Account
                </a>
                <a className="d-block small" href="/login">
                  Login Page
                </a>
              </div>
            </div>
          </div>
          {this.state.isloading ? (
            <LoadingModal
              show={this.state.isloading}
              message="Waiting for server response. Please wait..."
            />
          ) : null}
          <MessageDialog
            show={this.state.showMessageDialog}
            title={this.state.messageDialogTitle}
            message={this.state.messageDialogMessage}
            hideJumboDialog={this.navigateToLoginPage}
            buttonName="Done"
          />
        </div>
      );
    } else {
      return (
        <div className="container">
          <div align="center" className="col-md-12 col-sm-12">
            <img
              style={{ maxHeight: "100px", marginTop: "5%" }}
              src="img/logo.SVG"
            />
          </div>

          <div className="card card-register mx-auto mt-5">
            <p className="mx-5 mt-3">Invalid URL</p>
          </div>
        </div>
      );
    }
  }
}

export default RecoverPassword;
