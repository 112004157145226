import React, { Component } from "react";
import queryString from "query-string";
import SessionHelper from "../../SessionHelper";

class OAuthResponse extends Component {
  constructor(props) {
    super(props);
    let params = queryString.parse(this.props.location.search);
    this.state = { authID: params.token };

    let session = new SessionHelper();
    session.set("authID", params.token);
    session.set("userGroup", params.groupID);
    session.set("isLoggedIn", true);

    if (params.remember) {
      localStorage.authID = params.token;
      localStorage.userGroup = params.groupID;
      localStorage.isLoggedIn = true;
    }

    if (params.groupID == "1") {
      window.location.href = "/super-admin";
    } else {
      window.location.href = "/home";
    }
  }
  render() {
    return null;
  }
}

export default OAuthResponse;
