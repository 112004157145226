import React, { Component } from "react";
import queryString from "query-string";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class InterviewList extends Component {
  constructor(props) {
    super(props);
    let params = queryString.parse(props.location.search);
    this.state = {
      intervieweeListArray: null,
      dataLoaded: false,
      status: null,
      filter: params.filter,
      message: null,
      dataLoaded: false
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    let session = new SessionHelper();

    var url =
      this.props.baseURL +
      "interview-list/interviewee?authenticationToken=" +
      session.get("authID");

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.get(url);

    if (apiResponse["data"] === undefined) {
      this.setState({ dataLoaded: true });
    } else {
      let x = [];
      for (var keys in apiResponse["data"]["Interview List"]) {
        const index =
          apiResponse["data"]["Interview List"][keys]["INTERVIEWEE ID"];
        const interviewedOn =
          apiResponse["data"]["Interview List"][keys]["INTERVIEWED ON"];
        if (!x[index]) x[index] = [];
        x[index][interviewedOn] = [];
        for (var data in apiResponse["data"]["Interview List"][keys]) {
          x[index][interviewedOn][data] =
            apiResponse["data"]["Interview List"][keys][data];
        }
      }

      this.setState({
        dataLoaded: true,
        status: apiResponse["status"],
        message: apiResponse["message"],
        intervieweeListArray: x
      });
    }

    // fetch(url)
    //   .then(results => {
    //     return results.json();
    //   })
    //   .then(parsedData => {
    //     if (parsedData["data"] === undefined) {
    //       this.setState({ dataLoaded: true });
    //       return;
    //     }

    //     let x = [];
    //     for (var keys in parsedData["data"]["Interview List"]) {
    //       const index =
    //         parsedData["data"]["Interview List"][keys]["INTERVIEWEE ID"];
    //       const interviewedOn =
    //         parsedData["data"]["Interview List"][keys]["INTERVIEWED ON"];
    //       if (!x[index]) x[index] = [];
    //       x[index][interviewedOn] = [];
    //       for (var data in parsedData["data"]["Interview List"][keys]) {
    //         x[index][interviewedOn][data] =
    //           parsedData["data"]["Interview List"][keys][data];
    //       }
    //     }

    //     this.setState({
    //       dataLoaded: true,
    //       status: parsedData["status"],
    //       message: parsedData["message"],
    //       intervieweeListArray: x
    //     });

    // const script = document.createElement("script");
    // script.src = "js/demo/datatables-demo.js";
    // script.id = "1";
    // script.async = true;
    // document.body.replaceChild(script, document.getElementById("1"));

    // const script = document.createElement("script");
    // script.id = "1";
    // script.async = true;
    // const attr = this.state.filter
    //   ? "oSearch:{sSearch:'" + this.state.filter + "'},"
    //   : " ";
    // script.innerHTML =
    //   "$('#dataTable').DataTable({" + attr + "order: [[7,'desc']]});";
    // document.body.replaceChild(script, document.getElementById("1"));
    // })
    // .catch(error => console.log(error));
  }

  render() {
    let interviewListView;
    const x =
      this.state.intervieweeListArray != null &&
      this.state.status == 200 &&
      this.state.message != "No Records Found"
        ? true
        : false;
    if (x) {
      const interviewListData = Object.values(
        this.state.intervieweeListArray
      ).map((data1, index1) => {
        return Object.values(data1).map((data, index) => {
          const key = index1 + "-" + index;
          let options = {
            day: "numeric",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric"
          };
          let today = new Date(data["INTERVIEWED ON"]);
          return this.props.filter == "pending" &&
            data["STATUS"] == data["INTERVIEW STAGES"] ? null : (
            <tr key={key}>
              <td className="align-middle">
                <label>
                  <b
                    className="custom-anchor"
                    onClick={() =>
                      this.props.history.push(
                        "/super-admin/interviewee/details",
                        { id: data["INTERVIEWEE ID"] }
                      )
                    }
                  >
                    {data["INTERVIEWEE ID"]}
                  </b>
                </label>
              </td>
              <td className="align-middle">{data["INTERVIEWEE NAME"]}</td>
              <td className="align-middle">{data["COMPANY NAME"]}</td>
              <td className="align-middle">
                {data["POSITION"] ? (
                  data["POSITION"]
                ) : (
                  <span className="small text-muted">-- Not Specified --</span>
                )}
              </td>
              <td className="align-middle">
                {data["HIRED"] == null ? (
                  <span className="text-muted">
                    <h6>On Hold</h6>
                  </span>
                ) : data["HIRED"] == 1 ? (
                  <h6>Selected</h6>
                ) : (
                  "Not Selected"
                )}
              </td>

              <td className="align-middle">
                {data["CTC OFFERED"] == null ? (
                  <span className="small text-muted">-- Not Specified --</span>
                ) : (
                  data["CTC OFFERED"]
                )}
              </td>
              <td className="align-middle">
                {today.toLocaleDateString("en-IN", options)}
              </td>
              <td style={{ display: "none" }} className="align-middle">
                {data["INTERVIEWED ON"]}
              </td>
            </tr>
          );
        });
      });

      interviewListView =
        interviewListData.length > 0 ? (
          <table
            className="table table-bordered table-striped"
            id="dataTable"
            width="100%"
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th>Interviewee Id</th>
                <th>Interviewee Name</th>
                <th>Company Name</th>
                <th>For Position</th>
                <th>Status</th>
                <th>CTC Offered</th>
                <th>Interviewed On</th>
                <th style={{ display: "none" }}>Time</th>
              </tr>
            </thead>
            <tbody>{interviewListData}</tbody>
          </table>
        ) : (
          <p className="mb-0">No Pending Interviews!</p>
        );

      const script = document.createElement("script");
      script.src = "js/demo/datatables-demo.js";
      script.id = "1";
      script.async = true;
      document.body.replaceChild(script, document.getElementById("1"));
    } else if (this.state.dataLoaded === false) {
      interviewListView = <p className="mb-0">Loading data...</p>;
    } else {
      interviewListView = <p className="mb-0">No Data Found!</p>;
    }
    return (
      <div className="card mb-3">
        <div className="card-header">
          <h4>Interview List</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">{interviewListView}</div>
        </div>
      </div>
    );
  }
}

export default InterviewList;
