import React, { Component } from "react";
import CompanyDialog from "../dialogs/CompanyDialog";
import MessageDialog from "../dialogs/MessageDialog";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class RegisteredCompaniesList extends Component {
  constructor(props) {
    super(props);
    this.state = { showCompanyDetails: false, dataLoaded: false };

    this.showCompanyDetailsDialog = this.showCompanyDetailsDialog.bind(this);
    this.hideCompanyDetailsDialog = this.hideCompanyDetailsDialog.bind(this);
    this.removeCompanyArrayData = this.removeCompanyArrayData.bind(this);
    this.processingCompanyIndex = this.processingCompanyIndex.bind(this);
    this.hideMessageDialog = this.hideMessageDialog.bind(this);
  }

  hideCompanyDetailsDialog() {
    this.setState({ showCompanyDetails: false });
  }

  showCompanyDetailsDialog(companyData) {
    //this.setState({ showCompanyDetails: true, companyData: companyData });
    this.props.history.push("/super-admin/companyview", {
      companyData: companyData
    });
  }

  async componentDidMount() {
    let session = new SessionHelper();

    var url =
      this.props.baseURL +
      "company/list?authenticationToken=" +
      session.get("authID");

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.get(url);
    if (apiResponse["data"] === undefined) {
      this.setState({ dataLoaded: true });
    } else {
      let x = [];
      for (var keys in apiResponse["data"]["companies"]) {
        for (var data in apiResponse["data"]["companies"][keys]) {
          if (!x[keys]) x[keys] = [];
          x[keys][data] = apiResponse["data"]["companies"][keys][data];
        }
      }

      this.setState({
        dataLoaded: true,
        status: apiResponse["status"],
        message: apiResponse["message"],
        registeredCompaniesArray: x
      });
    }

    // fetch(url)
    //   .then(results => {
    //     return results.json();
    //   })
    //   .then(parsedData => {
    //     if (parsedData["data"] === undefined) {
    //       this.setState({ dataLoaded: true });
    //       return;
    //     }

    //     let x = [];
    //     for (var keys in parsedData["data"]["companies"]) {
    //       for (var data in parsedData["data"]["companies"][keys]) {
    //         if (!x[keys]) x[keys] = [];
    //         x[keys][data] = parsedData["data"]["companies"][keys][data];
    //       }
    //     }

    //     this.setState({
    //       dataLoaded: true,
    //       status: parsedData["status"],
    //       message: parsedData["message"],
    //       registeredCompaniesArray: x
    //     });

    //     // const script = document.createElement("script");
    //     // script.src = "js/demo/datatables-demo.js";
    //     // script.async = true;
    //     // document.body.appendChild(script);
    //   });
  }

  removeCompanyArrayData() {
    const messageTitle = "Unverified!";
    const message = (
      <p>
        <b>
          {
            this.state.registeredCompaniesArray[
              this.state.processingCompanyIndex
            ]["NAME"]
          }
        </b>{" "}
        has been marked as unverified company
      </p>
    );

    let x = this.state.registeredCompaniesArray;
    x.splice(this.state.processingCompanyIndex, 1);

    this.setState({
      registeredCompaniesArray: x,
      showMessageDialog: true,
      messageDialogTitle: messageTitle,
      messageDialogMessage: message
    });
  }

  processingCompanyIndex(index) {
    this.setState({ processingCompanyIndex: index });
  }

  hideMessageDialog() {
    this.setState({
      showMessageDialog: false,
      messageDialogTitle: null,
      messageDialogMessage: null
    });
  }

  render() {
    let registeredCompaniesList = null;
    const showDataTable =
      this.state.dataLoaded === true && this.state.status === 200
        ? true
        : false;

    if (showDataTable) {
      const registeredCompaniesTableData = Object.values(
        this.state.registeredCompaniesArray
      ).map((data, index) => {
        return (
          <tr
            key={index}
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.showCompanyDetailsDialog(data);
              this.processingCompanyIndex(index);
            }}
          >
            <td className="align-middle">{data["ID"]}</td>
            <td className="align-middle">{data["NAME"]}</td>
            <td className="align-middle">
              <a
                href={"http://" + data["WEBSITE"]}
                target="blank"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {data["WEBSITE"]}
              </a>
            </td>
            <td className="align-middle">{data["EMAIL_ADDRESS"]}</td>
            <td className="align-middle">{data["PHONE_NO"]}</td>
            <td className="align-middle">{data["TOTAL_EMPLOYEES"]}</td>
            <td style={{ display: "none" }} className="align-middle">
              {data["CREATED_ON"]}
            </td>
          </tr>
        );
      });

      registeredCompaniesList = (
        <table
          className="table table-bordered mb-0 table-hover"
          id="dataTable"
          width="100%"
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th>Company ID</th>
              <th>Company Name</th>
              <th>Website</th>
              <th>Email Address</th>
              <th>Phone Number</th>
              <th>Employee Accounts</th>
              <th style={{ display: "none" }}>Account Created On</th>
            </tr>
          </thead>
          <tbody>{registeredCompaniesTableData}</tbody>
        </table>
      );
    } else if (this.state.dataLoaded === false) {
      registeredCompaniesList = <p className="mb-0">Loading data...</p>;
    } else {
      registeredCompaniesList = (
        <p className="mb-0">There are no registered comapnies</p>
      );
    }

    return (
      <div className="card mb-3">
        <div className="card-header align-middle">
          <h4>Registered Companies List</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">{registeredCompaniesList}</div>
        </div>

        {this.state.showCompanyDetails ? (
          <CompanyDialog
            baseURL={this.props.baseURL}
            show={this.state.showCompanyDetails}
            hide={this.hideCompanyDetailsDialog}
            companyData={this.state.companyData}
            processArray={this.removeCompanyArrayData}
          />
        ) : null}

        {this.state.showMessageDialog ? (
          <MessageDialog
            show={this.state.showMessageDialog}
            title={this.state.messageDialogTitle}
            message={this.state.messageDialogMessage}
            hideJumboDialog={this.hideMessageDialog}
            buttonName="Ok"
          />
        ) : null}
      </div>
    );
  }
}

export default RegisteredCompaniesList;
