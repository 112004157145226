import React, { Component } from "react";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import CompanyInterviewList from "./CompanyInterviewList";
import Footer from "./Footer";
import Dashboard from "./Dashboard";
import IntervieweeDetails from "./IntervieweeDetails";
import EmployeeList from "./EmployeeList";
import Logout from "../dialogs/Logout";
import AddEmployee from "./AddEmployee";
import EmployeeDetails from "./EmployeeDetails";
import UpdateEmailAddress from "./UpdateEmailAddress";
import UpdateInterview from "./UpdateInterview";
import AccountSettings from "./AccountSettings";
import ActivityLog from "./ActivityLog";
import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import SearchCandidate from "./SearchCandidate";
import ScheduleInterview from "./ScheduleInterview";
import CompanyProfile from "./CompanyProfile";
import EditCompanyProfile from "./EditCompanyProfile";
import SessionHelper from "../../SessionHelper";

export default class Home extends Component {
  session = new SessionHelper();

  dashboardTabID = 0;
  interviewListTabID = 1;
  subEmployeeListTabID = 2;
  activityLog = 3;
  updateEmailAddressTabID = 4;

  constructor(props) {
    super(props);
    console.log("User ID : " + this.session.get("userGroup"));
    if (
      this.session.get("userGroup") == "1" &&
      this.session.get("authID") != "null"
    ) {
      window.location.href = "/super-admin";
    } else if (
      !this.session.get("isLoggedIn") ||
      this.session.get("authID") == "null"
    ) {
      window.location.href = "/login";
    }

    this.state = {
      viewId: props.viewId,
      navBarToggle: false
    };

    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    // const scripts = new ScriptsLoader();
    // scripts.loadScripts();
  }

  logout() {
    // this.session.set('authID',null);
    // this.session.set('userID',null);
    // this.session.set('isLoggedIn',null);

    this.session.clear();
    window.location.href = "/login";
  }

  render() {
    return (
      <Router history={createBrowserHistory()}>
        <React.Fragment>
          <div className="wave -three" />
          <div id="spinner" />
          <div className="main-wrapper">
            <NavBar
              baseURL={this.props.baseURL}
              handleLinkClick={this.handleLinkClick}
            />

            <SideBar baseURL={this.props.baseURL} />
            <div className="app-content">
              <section className="section">
                <Switch>
                  <Redirect from="/home" exact to="/home/dashboard" />

                  <Route
                    path="/home/dashboard"
                    render={props => (
                      <Dashboard {...props} baseURL={this.props.baseURL} />
                    )}
                  />

                  <Route
                    path="/home/search/candidate"
                    render={props => (
                      <SearchCandidate
                        {...props}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Route
                    path="/home/interview/schedule"
                    render={props => (
                      <ScheduleInterview
                        {...props}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Route
                    path="/home/interview/list"
                    exact
                    render={props => (
                      <CompanyInterviewList
                        {...props}
                        showHeader={true}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Route
                    path="/home/interviewee/details"
                    exact
                    render={props => (
                      <IntervieweeDetails
                        {...props}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Route
                    path="/home/update/interview"
                    exact
                    render={props => (
                      <UpdateInterview
                        {...props}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Route
                    path="/home/employees/list"
                    exact
                    render={props => (
                      <EmployeeList {...props} baseURL={this.props.baseURL} />
                    )}
                  />

                  <Route
                    path="/home/employee/details"
                    exact
                    render={props => (
                      <EmployeeDetails
                        {...props}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Route
                    path="/home/add/employee"
                    exact
                    render={props => (
                      <AddEmployee {...props} baseURL={this.props.baseURL} />
                    )}
                  />

                  <Route
                    path="/home/company/profile"
                    exact
                    render={props => (
                      <CompanyProfile {...props} baseURL={this.props.baseURL} />
                    )}
                  />

                  <Route
                    path="/home/company/editprofile"
                    exact
                    render={props => (
                      <EditCompanyProfile
                        {...props}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Route
                    path="/home/activitylog"
                    exact
                    render={props => (
                      <ActivityLog {...props} baseURL={this.props.baseURL} />
                    )}
                  />

                  <Route
                    path="/home/update/emailaddress"
                    exact
                    render={props => (
                      <UpdateEmailAddress
                        {...props}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Route
                    path="/home/settings"
                    exact
                    render={props => (
                      <AccountSettings
                        {...props}
                        baseURL={this.props.baseURL}
                      />
                    )}
                  />

                  <Redirect to="/home/dashboard" />
                </Switch>
              </section>
            </div>
            <Footer />
            <Logout logout={this.logout} />
          </div>
        </React.Fragment>
      </Router>
    );
  }
}
