import React, { Component } from "react";
import MessageDialog from "../dialogs/MessageDialog";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class EmployeeDetails extends Component {
  session = new SessionHelper();

  constructor(props) {
    super(props);
    let data;
    if (this.session.get("userGroup") == "1") {
      data = props.employeeData;
    } else {
      data = props.location.state.data;
    }

    this.state = {
      showWarningDialog: false,
      showConfirmationDialog: false,
      employeeData: data,
      updatedPermission: data["PERMISSION"]
    };
    this.updateEmployeePrevileges = this.updateEmployeePrevileges.bind(this);
    this.employeeAccount = this.employeeAccount.bind(this);
    this.showDialog = this.showDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.hideMessageDialog = this.hideMessageDialog.bind(this);
    this.showEmployeeConfirmationDialog = this.showEmployeeConfirmationDialog.bind(
      this
    );
    this.hideEmployeeConfirmationDialog = this.hideEmployeeConfirmationDialog.bind(
      this
    );
  }

  showEmployeeConfirmationDialog() {
    this.setState({ showConfirmationDialog: true });
  }

  hideEmployeeConfirmationDialog() {
    this.setState({ showConfirmationDialog: false });
  }

  showDialog() {
    this.setState({ showWarningDialog: true });
  }

  hideDialog() {
    this.setState({ showWarningDialog: false });
  }

  hideMessageDialog() {
    this.setState({
      showJumboDialog: false,
      jumboDialogTitle: null,
      jumboDialogMessage: null
    });
  }

  setPermission(id, index) {
    index = parseInt(index);
    let replacement = document.getElementById(id).checked == true ? "1" : "0";

    this.state.updatedPermission =
      this.state.updatedPermission.substr(0, index) +
      replacement +
      this.state.updatedPermission.substr(index + 1);
  }

  togglePrevilegeView() {
    if (document.getElementById("updatePrevileges").checked) {
      document.getElementById("previlegesFieldset").disabled = false;
    } else {
      document.getElementById("previlegesFieldset").disabled = true;
    }
  }

  async updateEmployeePrevileges() {
    if (this.state.updatedPermission == this.state.employeeData["PERMISSION"]) {
      const jumbotronTitle = <font className="mr-5 pr-5">Server Response</font>;
      const jumbotronMessage = "No changes were made!";
      this.setState({
        showJumboDialog: true,
        jumboDialogTitle: jumbotronTitle,
        jumboDialogMessage: jumbotronMessage
      });
    } else {
      let parameters = {
        employeeID: this.state.employeeData["USER_ID"],
        employeePermissions: this.state.updatedPermission,
        authenticationToken: this.session.get("authID")
      };

      let apiHelper = new RestAPIHelper();
      const apiresponse = await apiHelper.post(
        this.props.baseURL + "update/employee/permission",
        parameters
      );
      if (apiresponse["status"] == 200) {
        let x = this.state.employeeData;
        x["PERMISSION"] = this.state.updatedPermission;

        const jumbotronTitle = (
          <font className="mr-5 pr-5">Server Response</font>
        );
        const jumbotronMessage = apiresponse["message"];

        this.setState({
          showJumboDialog: true,
          jumboDialogTitle: jumbotronTitle,
          jumboDialogMessage: jumbotronMessage,
          employeeData: x
        });
      } else {
        const jumbotronTitle = (
          <font className="mr-5 pr-5">Error Occurred</font>
        );
        const jumbotronMessage = apiresponse["message"];
        this.setState({
          showJumboDialog: true,
          jumboDialogTitle: jumbotronTitle,
          jumboDialogMessage: jumbotronMessage
        });
      }
    }
  }

  async employeeAccount(accountStatus) {
    this.hideEmployeeConfirmationDialog();
    let parameters = {
      employeeID: this.state.employeeData["USER_ID"],
      accountStatus: accountStatus,
      authenticationToken: this.session.get("authID")
    };

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.post(
      this.props.baseURL + "employee/account",
      parameters
    );
    if (apiResponse["status"] == 200) {
      let x = this.state.employeeData;
      x["IS_ACTIVE"] = x["IS_ACTIVE"] == "YES" ? "NO" : "YES";

      const jumbotronTitle =
        x["IS_ACTIVE"] == "YES" ? (
          <font color="green">Activated</font>
        ) : (
          <font color="red">Deactivated</font>
        );
      const jumbotronMessage =
        x["IS_ACTIVE"] == "YES" ? (
          <font className="mr-5">Employee Account has been Activated</font>
        ) : (
          <font className="mr-5">Employee Account has been Deactivated</font>
        );

      this.setState({
        showJumboDialog: true,
        jumboDialogTitle: jumbotronTitle,
        jumboDialogMessage: jumbotronMessage,
        employeeData: x
      });
    } else {
      this.setState({
        showJumboDialog: true,
        jumboDialogTitle: "Error",
        jumboDialogMessage: apiResponse["message"]
      });
    }
  }

  wordCapitalize(word) {
    let res = "";
    const len = word.length;
    let doCap = true;
    for (let i = 0; i < len; i++) {
      if (word.charAt(i) == " ") {
        doCap = true;
        res += word.charAt(i);
      } else if (word.charAt(i) == "_") {
        doCap = true;
        res += " ";
      } else {
        if (doCap) {
          res += word.charAt(i).toUpperCase();
          doCap = false;
        } else {
          res += word.charAt(i).toLowerCase();
        }
      }
    }
    return res;
  }

  render() {
    let employeeID,
      idType,
      employeeName,
      emailAddress,
      phoneNumber,
      accountCreatedOn,
      group,
      isActive;

    const employeePhotoURL =
      this.props.baseURL +
      "user/photo?userID=" +
      this.state.employeeData["USER_ID"];

    if (this.state.employeeData != null) {
      employeeID = this.state.employeeData["USER_ID"];
      idType = this.state.employeeData["ID_TYPE"];
      employeeName = this.state.employeeData["NAME"];
      emailAddress = this.state.employeeData["EMAIL_ID"];
      phoneNumber = this.state.employeeData["PHONE_NO"];
      accountCreatedOn = this.state.employeeData["CREATED_ON"];
      group = this.state.employeeData["GROUP"];
      isActive = this.state.employeeData["IS_ACTIVE"];
    }

    employeeID = employeeID != null ? employeeID : "---";
    idType = idType != null ? this.wordCapitalize(idType) : "Id Type";
    employeeName =
      employeeName != null ? this.wordCapitalize(employeeName) : "---";
    emailAddress = emailAddress != null ? emailAddress : "---";
    phoneNumber = phoneNumber != null && phoneNumber != 0 ? phoneNumber : "---";
    accountCreatedOn = accountCreatedOn != null ? accountCreatedOn : "---";
    group = group != null ? this.wordCapitalize(group) : "---";

    return (
      <React.Fragment>
        <div className="card mb-3">
          <div className="card-header">
            <h4>Employee Details</h4>
          </div>
          <div className="card-body">
            <div
              className="row"
              style={{
                maxWidth: "600px",
                margin: "auto",
                marginLeft: "1%"
              }}
            >
              <div className="col-md-4 col-sm-12 mb-2">
                <img
                  style={{
                    maxWidth: "140px",
                    maxHeight: "140px",
                    minHeight: "140px",
                    minWidth: "140px",
                    objectFit: "cover"
                  }}
                  src={employeePhotoURL}
                  className="border rounded-circle"
                />
              </div>
              <div className="col-md-8 col-sm-12">
                <b>Employee Name : </b>
                {employeeName}
                <br />
                <b>{idType} : </b>
                {employeeID}
                <br />
                <b>Email Address : </b>
                {emailAddress}
                <br />
                <b>Phone Number : </b>
                {phoneNumber}
                <br />
                <b>Account Created On : </b>
                {accountCreatedOn}
                <br />
                <b>Group : </b>
                {group}
                <br />
                {isActive == "YES" ? (
                  <a
                    className="btn btn-sm btn-danger mt-3"
                    onClick={this.showEmployeeConfirmationDialog}
                    style={{ color: "white" }}
                  >
                    Deactivate Account
                  </a>
                ) : (
                  <a
                    className="btn btn-sm btn-success mt-3"
                    onClick={this.showEmployeeConfirmationDialog}
                    style={{ color: "white" }}
                  >
                    Activate Account
                  </a>
                )}
              </div>
            </div>
            <br />
            <div className="border rounded p-3" style={{ maxWidth: "600px" }}>
              <div className="align-middle">
                <label className="m-0">
                  <font size="4">Previleges</font>
                </label>

                <div
                  className="custom-control custom-checkbox"
                  style={{ float: "right" }}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    onClick={this.togglePrevilegeView}
                    id="updatePrevileges"
                  />
                  <label
                    className="custom-control-label mr-2"
                    htmlFor="updatePrevileges"
                  >
                    <small>Edit Previleges</small>
                  </label>
                </div>
              </div>
              <hr className="my-3" />
              <fieldset id="previlegesFieldset" disabled>
                <div className="form-row">
                  <div className="col-md-6 p-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        defaultChecked={
                          this.state.employeeData["PERMISSION"].charAt(1) == "1"
                            ? true
                            : false
                        }
                        onClick={() => this.setPermission("p1", 1)}
                        id="p1"
                      />
                      <label className="custom-control-label" htmlFor="p1">
                        Can Create Sub Employee's
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        defaultChecked={
                          this.state.employeeData["PERMISSION"].charAt(2) == "1"
                            ? true
                            : false
                        }
                        onClick={() => this.setPermission("p2", 2)}
                        id="p2"
                      />
                      <label className="custom-control-label" htmlFor="p2">
                        Add Interview
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 p-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        defaultChecked={
                          this.state.employeeData["PERMISSION"].charAt(3) == "1"
                            ? true
                            : false
                        }
                        onClick={() => this.setPermission("p3", 3)}
                        id="p3"
                      />
                      <label className="custom-control-label" htmlFor="p3">
                        Can View CTC
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        defaultChecked={
                          this.state.employeeData["PERMISSION"].charAt(4) == "1"
                            ? true
                            : false
                        }
                        onClick={() => this.setPermission("p4", 4)}
                        id="p4"
                      />
                      <label className="custom-control-label" htmlFor="p4">
                        Can View Only In Mobile
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 p-2">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        defaultChecked={
                          this.state.employeeData["PERMISSION"].charAt(5) == "1"
                            ? true
                            : false
                        }
                        onClick={() => this.setPermission("p5", "5")}
                        id="p5"
                      />
                      <label className="custom-control-label" htmlFor="p5">
                        Can Scan Biometrics
                      </label>
                    </div>
                  </div>
                </div>
                <center className="mt-3">
                  <input
                    type="submit"
                    className="btn btn-primary btn-block"
                    style={{ maxWidth: "200px" }}
                    href="#"
                    onClick={this.updateEmployeePrevileges}
                    value="Update Permission"
                  />
                </center>
              </fieldset>
            </div>

            <br />
          </div>
        </div>

        <MessageDialog
          show={this.state.showConfirmationDialog}
          showOnTop={true}
          title={
            this.state.employeeData["IS_ACTIVE"] == "YES"
              ? "Deactivate Account"
              : "Activate Account"
          }
          message={
            this.state.employeeData["IS_ACTIVE"] == "YES"
              ? "Are you sure you want to Deactivate this Account"
              : "Are you sure you want to Activate this Account"
          }
          toggle={this.hideEmployeeConfirmationDialog}
          hideJumboDialog={() =>
            this.employeeAccount(
              this.state.employeeData["IS_ACTIVE"] == "YES" ? 0 : 1
            )
          }
          buttonColor={
            this.state.employeeData["IS_ACTIVE"] == "YES" ? "danger" : "success"
          }
          buttonName="Proceed"
        />

        {this.state.showJumboDialog ? (
          <MessageDialog
            show={this.state.showJumboDialog}
            title={this.state.jumboDialogTitle}
            message={this.state.jumboDialogMessage}
            hideJumboDialog={this.hideMessageDialog}
            buttonName="Ok"
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default EmployeeDetails;
