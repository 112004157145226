import React, { Component } from "react";
import { Link } from "react-router-dom";

class ScheduleInterview extends Component {
  constructor(props) {
    super(props);
    this.state = { showSearchResult: false };

    this.searchCandidate = this.searchCandidate.bind(this);
  }

  searchCandidate() {
    this.setState({ showSearchResult: true });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-header">
          <h4 className="page-title">Schedule Interview</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/home/dashboard" className="text-light-color">
                Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Schedule Interview
            </li>
          </ol>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h4>Schedule Interview for Candidate</h4>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Name</label>
                  <div className="col-md-9">
                    <input className="form-control" type="text" name="name" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Email</label>
                  <div className="col-md-9">
                    <input className="form-control" type="text" name="email" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Phone</label>
                  <div className="col-md-9">
                    <input className="form-control" type="text" name="phone" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Stages</label>
                  <div className="col-md-9">
                    <input className="form-control" type="text" name="stages" />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Position</label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="text"
                      name="position"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Date &amp; Time
                  </label>
                  <div className="col-md-9">
                    <div className="form-group" id="date-picker">
                      <div className="input-group date">
                        <div className="input-group-addon">
                          <i className="fa fa-calendar" />
                        </div>
                        <input
                          type="text"
                          className="form-control pull-right"
                          id="datepicker"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Branch</label>
                  <div className="col-md-9">
                    <select className="form-control">
                      <option>Gachibowli</option>
                      <option>Hi-Tech City</option>
                      <option>Banjara Hills</option>
                      <option>Begumpet</option>
                      <option>Jubilee Hills</option>
                    </select>
                  </div>
                </div>

                <div className="form-group row mb-0">
                  <label className="col-md-3 col-form-label">Interviewer</label>
                  <div className="col-md-9">
                    <input
                      className="form-control"
                      type="text"
                      name="employees-list"
                    />
                  </div>
                </div>
                <br />

                <div className="form-group row">
                  <label for="inputPhone" className="col-md-3 col-form-label">
                    Upload Resume
                  </label>
                  <div className="col-md-9">
                    <div
                      className="form-group upload-btn-wrapper files mb-lg-0"
                      id="file"
                    >
                      <input
                        type="file"
                        className="form-control1"
                        multiple=""
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group mb-0 mt-2 row justify-content-end">
                  <div className="col-md-9">
                    <button type="submit" className="btn btn-info">
                      Schedule Interview
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ScheduleInterview;
