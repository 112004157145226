import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer">
        <div className="text-center">
          Copyright © TruHire 2019. Design By&nbsp;
          <a href="https://www.v-empower.com/">V-Empower Inc.</a>
        </div>
      </footer>
    );
  }
}

export default Footer;
