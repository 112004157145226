import React, { Component } from "react";
import { Alert, Collapse } from "reactstrap";
import LoadingModal from "../dialogs/LoadingModal";
import MessageDialog from "../dialogs/MessageDialog";
import RestAPIHelper from "../../RestAPIHelper";

class Signup extends Component {
  COMPANY_NAME_MIN_LENGTH = 4;
  COMPANY_NAME_MAX_LENGTH = 30;

  PHONE_NUM_MIN_LENGTH = 7;
  PHONE_NUM_MAX_LENGTH = 10;

  AADHAR_NUMBER_LENGTH = 12;
  DRIVING_LICENCE_NUMBER_LENGTH = 15;
  PASSPORT_NUMBER_LENGTH = 8;

  USER_NAME_MIN_LENGTH = 3;
  USER_NAME_MAX_LENGTH = 16;

  PASSWORD_MIN_LENGTH = 6;
  PASSWORD_MAX_LENGTH = 20;

  COMPANY_LOGO_MAX_SIZE = 5242880; //equals 5MB

  idCompanyID = "companyID";
  idCompanyIDType = "companyIDType";
  idCompanyName = "companyName";
  idCompanyPhoneNumber = "companyPhoneNumber";
  idCompanyEmailID = "companyEmailID";
  idCompanyWebsite = "website";
  idCompanyDescription = "description";
  idCompanyLogo = "companyLogo";
  idCompanyLogoLabel = "companyPhotoLabel";

  // idUserID = "userID";
  // idUserIDType = "idType";
  idDesignation = "designation";
  idUserName = "userName";
  idUserEmailID = "userEmailID";
  idUserPhoneNumber = "userPhoneNumber";
  idPassword = "password";
  idRePassword = "confirmPassword";
  idUserPhoto = "userPhoto";
  idUserPhotoLabel = "userPhotoLabel";

  companyIDType = "";
  designation = "";

  constructor(props) {
    super(props);
    this.state = {
      errorStatus: null,
      showError: false,
      errorMessage: null,
      isloading: false
    };

    this.textInput = React.createRef();
    this.focus = this.focus.bind(this);

    this.performSignup = this.performSignup.bind(this);
    this.onInputTextChangeHandler = this.onInputTextChangeHandler.bind(this);
    this.displaySelectedFIleName = this.displaySelectedFIleName.bind(this);
    this.navigateToLoginPage = this.navigateToLoginPage.bind(this);
    this.setCompanyIDType = this.setCompanyIDType.bind(this);

    this.textInput = React.createRef();
  }

  componentDidUpdate() {
    this.focus();
  }

  focus() {
    if (this.textInput.current !== null) {
      this.textInput.current.focus();
    }
  }

  setDesignation(idtype) {
    this.designation = idtype;
    document.getElementById("idtypeButton").innerHTML = this.designation;
  }

  setCompanyIDType(cIDType) {
    this.companyIDType = cIDType;
    let val = "";
    if (cIDType === "COMPANY PAN") val = "Company PAN Number";
    else if (cIDType === "COMPANY REGISTRATION NUMBER")
      val = "Company Registration Number";
    else val = cIDType;
    document.getElementById("companyidtypeButton").innerHTML = val;
  }

  displaySelectedFIleName(id, label, defaultMsg) {
    const fileName = document.getElementById(id).value;
    if (fileName) {
      document.getElementById(label).innerHTML = fileName.substr(
        fileName.lastIndexOf("\\") + 1
      );
    } else {
      document.getElementById(label).innerHTML = defaultMsg;
    }
  }

  onInputTextChangeHandler() {
    this.setState({
      errorStatus: null,
      showError: false,
      errorMessage: null
    });
  }

  navigateToLoginPage() {
    window.location.href = "/login";
    this.setState({
      showJumboDialog: false,
      jumboDialogTitle: null,
      jumboDialogMessage: null
    });
  }

  async performSignup() {
    this.setState({
      isloading: true,
      errorStatus: null,
      showError: false,
      errorMessage: null
    });

    const companyName = document.getElementById(this.idCompanyName).value;
    const companyPhoneNumber = document.getElementById(
      this.idCompanyPhoneNumber
    ).value;
    const companyEmailID = document.getElementById(this.idCompanyEmailID).value;
    const companyWebsite = document.getElementById(this.idCompanyWebsite).value;
    const companyDescription = document.getElementById(
      this.idCompanyDescription
    ).value;
    const companyLogo = document.getElementById(this.idCompanyLogo).files[0];

    const companyUID = document.getElementById(this.idCompanyID).value;

    //const userID = document.getElementById(this.idUserID).value;
    const userName = document.getElementById(this.idUserName).value;
    const userEmailID = document.getElementById(this.idUserEmailID).value;
    const userPhoneNumber = document.getElementById(this.idUserPhoneNumber)
      .value;
    const userPassword = document.getElementById(this.idPassword).value;
    const userRePassword = document.getElementById(this.idRePassword).value;
    // const userPhoto = document.getElementById(this.idCompanyLogo).files[0];

    if (!companyName) {
      this.setState({
        showError: true,
        errorStatus: 201,
        errorMessage: "Please enter your company name"
      });
    } else if (
      companyName.length < this.COMPANY_NAME_MIN_LENGTH ||
      companyName.length > this.COMPANY_NAME_MAX_LENGTH
    ) {
      this.setState({
        showError: true,
        errorStatus: 201,
        errorMessage:
          "Company Name should be of atleast " +
          this.COMPANY_NAME_MIN_LENGTH +
          ", and atmost " +
          this.COMPANY_NAME_MAX_LENGTH +
          " characters"
      });
    } else if (!companyPhoneNumber) {
      this.setState({
        showError: true,
        errorStatus: 205,
        errorMessage: "Please enter your company phone number"
      });
    } else if (
      companyPhoneNumber.length < this.PHONE_NUM_MIN_LENGTH ||
      companyPhoneNumber.length > this.PHONE_NUM_MAX_LENGTH
    ) {
      this.setState({
        showError: true,
        errorStatus: 205,
        errorMessage:
          "Company Phone number should be of atleast " +
          this.COMPANY_NAME_MIN_LENGTH +
          ", and atmost " +
          this.COMPANY_NAME_MAX_LENGTH +
          " numbers in length"
      });
    } else if (!companyEmailID) {
      this.setState({
        showError: true,
        errorStatus: 203,
        errorMessage: "Please enter your company email address"
      });
    } else if (!companyUID) {
      this.setState({
        showError: true,
        errorStatus: 211,
        errorMessage: "Please enter your company unique ID"
      });
    } else if (!this.companyIDType) {
      this.setState({
        showError: true,
        errorStatus: 212,
        errorMessage: "Please select a valid company ID type"
      });
    } else if (!userName) {
      this.setState({
        showError: true,
        errorStatus: 215,
        errorMessage: "Please enter your name"
      });
    } else if (
      userName.length < this.USER_NAME_MIN_LENGTH ||
      userName.length > this.USER_NAME_MAX_LENGTH
    ) {
      this.setState({
        showError: true,
        errorStatus: 215,
        errorMessage:
          "User Name should has to be of atleast " +
          this.USER_NAME_MIN_LENGTH +
          ", and atmost " +
          this.USER_NAME_MAX_LENGTH +
          " characters"
      });
    } else if (!this.designation) {
      this.setState({
        showError: true,
        errorStatus: 212,
        errorMessage: "Please select a valid designation type"
      });
    } else if (!userEmailID) {
      this.setState({
        showError: true,
        errorStatus: 217,
        errorMessage: "Please enter your email address"
      });
    } else if (!userPhoneNumber) {
      this.setState({
        showError: true,
        errorStatus: 227,
        errorMessage: "Please enter your phone number"
      });
    } else if (!userPassword) {
      this.setState({
        showError: true,
        errorStatus: 219,
        errorMessage: "Please enter the password"
      });
    } else if (
      userPassword.length < this.PASSWORD_MIN_LENGTH ||
      userPassword.length > this.PASSWORD_MAX_LENGTH
    ) {
      this.setState({
        showError: true,
        errorStatus: 219,
        errorMessage:
          "Password should be of atleast " +
          this.PASSWORD_MIN_LENGTH +
          ", and atmost " +
          this.PASSWORD_MAX_LENGTH +
          " characters"
      });
    } else if (userPassword !== userRePassword) {
      this.setState({
        showError: true,
        errorStatus: 219.1,
        errorMessage: "Retype password doesn't match with the password"
      });
    } else if (
      companyLogo &&
      companyLogo["size"] > this.COMPANY_LOGO_MAX_SIZE
    ) {
      this.setState({
        showError: true,
        errorStatus: 207,
        errorMessage: "Company photo size should be less than 5MB"
      });
    } else {
      const url = this.props.baseURL + "register/company";
      let parameters = {
        companyID: companyUID,
        companyIDType: this.companyIDType,
        companyName: companyName,
        companyPhoneNumber: companyPhoneNumber,
        companyEmailID: companyEmailID,
        website: companyWebsite,
        description: companyDescription,
        designation: this.designation,
        userName: userName,
        userEmailID: userEmailID,
        userPhoneNumber: userPhoneNumber,
        password: userPassword
      };

      let y = companyLogo ? (parameters.companyLogo = companyLogo) : null;

      let apiHelper = new RestAPIHelper();
      const apiResponse = await apiHelper.post(url, parameters);
      if (apiResponse["status"] == 200) {
        const dialogTitle = "Company Registered";
        const dialogMessage =
          "Your company has been registered. You can't login into your account untill your company gets verified. And you'll be notified by email, when the company gets verified.";
        this.setState({
          showJumboDialog: true,
          jumboDialogTitle: dialogTitle,
          jumboDialogMessage: dialogMessage
        });
      } else {
        this.setState({
          showError: true,
          errorStatus: apiResponse["status"],
          errorMessage: apiResponse["message"]
        });
      }
    }

    this.setState({ isloading: false });
    // this.focus();
  }

  render() {
    if (
      this.state.errorStatus === 212 ||
      this.state.errorStatus === 214 ||
      this.state.errorStatus === 221 ||
      this.state.errorStatus === 207 ||
      this.state.errorStatus === 226 ||
      this.state.errorStatus === 202 ||
      this.state.errorStatus === 204 ||
      this.state.errorStatus === 206
    ) {
      document.documentElement.scrollTop = 0;
    }
    return (
      <div className="container mb-5">
        <div align="center" className="col-md-12 col-sm-12">
          <img
            style={{ maxHeight: "100px", marginTop: "5%" }}
            src="img/logo.SVG"
          />
        </div>

        <div className="card card-register mx-auto mt-5">
          <div id="header1" className="card-header">
            <font size="4">
              <center>Enroll your company</center>
            </font>
          </div>
          <Collapse
            isOpen={
              this.state.errorStatus !== null &&
              (this.state.errorStatus === 212 ||
                this.state.errorStatus === 214 ||
                this.state.errorStatus === 221 ||
                this.state.errorStatus === 207 ||
                this.state.errorStatus === 226)
            }
          >
            <Alert color="danger" className="rounded-0 mb-0 px-2 py-1">
              {this.state.errorMessage}
            </Alert>
          </Collapse>
          <div className="card-body">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-label-group">
                      <input
                        type="text"
                        ref={
                          (this.state.errorStatus === 201 ||
                            this.state.errorStatus === 202 ||
                            this.state.errorStatus === 225) &&
                          this.state.showError
                            ? this.textInput
                            : null
                        }
                        onChange={this.onInputTextChangeHandler}
                        id={this.idCompanyName}
                        className={
                          (this.state.errorStatus === 201 ||
                            this.state.errorStatus === 202 ||
                            this.state.errorStatus === 225) &&
                          this.state.showError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Company Name"
                        required="required"
                      />
                      <div className="invalid-feedback">
                        {this.state.errorMessage}
                      </div>
                      <label
                        htmlFor={this.idCompanyName}
                        style={{ cursor: "text" }}
                      >
                        Company Name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-label-group">
                      <input
                        invalid={
                          (this.state.errorStatus === 205 ||
                            this.state.errorStatus === 206) &&
                          this.state.showError
                        }
                        ref={
                          (this.state.errorStatus === 205 ||
                            this.state.errorStatus === 206) &&
                          this.state.showError
                            ? this.textInput
                            : null
                        }
                        type="text"
                        onChange={this.onInputTextChangeHandler}
                        id={this.idCompanyPhoneNumber}
                        className={
                          (this.state.errorStatus === 205 ||
                            this.state.errorStatus === 206) &&
                          this.state.showError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Company Phone Number"
                        required="required"
                      />
                      <div className="invalid-feedback">
                        {this.state.errorMessage}
                      </div>
                      <label
                        htmlFor={this.idCompanyPhoneNumber}
                        style={{ cursor: "text" }}
                      >
                        Company Phone Number
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="form-label-group">
                  <input
                    ref={
                      (this.state.errorStatus === 203 ||
                        this.state.errorStatus === 204 ||
                        this.state.errorStatus === 224) &&
                      this.state.showError
                        ? this.textInput
                        : null
                    }
                    type="email"
                    onChange={this.onInputTextChangeHandler}
                    id={this.idCompanyEmailID}
                    className={
                      (this.state.errorStatus === 203 ||
                        this.state.errorStatus === 204 ||
                        this.state.errorStatus === 224) &&
                      this.state.showError
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Company Email address"
                    required="required"
                  />
                  <div className="invalid-feedback">
                    {this.state.errorMessage}
                  </div>
                  <label
                    htmlFor={this.idCompanyEmailID}
                    style={{ cursor: "text" }}
                  >
                    Company Email address
                  </label>
                </div>
              </div>

              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-7">
                    <div className="form-label-group">
                      <input
                        ref={
                          (this.state.errorStatus === 211 ||
                            this.state.errorStatus === 213 ||
                            this.state.errorStatus === 223) &&
                          this.state.showError
                            ? this.textInput
                            : null
                        }
                        type="text"
                        onChange={this.onInputTextChangeHandler}
                        id={this.idCompanyID}
                        className={
                          (this.state.errorStatus === 211 ||
                            this.state.errorStatus === 213 ||
                            this.state.errorStatus === 223) &&
                          this.state.showError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Company Unique Identity"
                        required="required"
                      />
                      <div className="invalid-feedback">
                        {this.state.errorMessage}
                      </div>
                      <label
                        htmlFor={this.idCompanyID}
                        style={{ cursor: "text" }}
                      >
                        Company Unique Identity
                      </label>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-label-group">
                      <div className="dropdown">
                        <button
                          id="companyidtypeButton"
                          className="btn btn-primary dropdown-toggle h-100"
                          type="button"
                          style={{
                            minHeight: "43px",
                            float: "right",
                            minWidth: "100px"
                          }}
                          data-toggle="dropdown"
                        >
                          Select Company ID Type
                          <span className="caret" />
                        </button>
                        <ul className="dropdown-menu">
                          <li
                            className="px-2 pb-2 border-bottom"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.setCompanyIDType("COMPANY PAN")}
                          >
                            <a>Company PAN Number</a>
                          </li>
                          <li
                            className=" px-2 pt-2"
                            onClick={() =>
                              this.setCompanyIDType(
                                "COMPANY REGISTRATION NUMBER"
                              )
                            }
                          >
                            <a style={{ cursor: "pointer" }}>
                              Company Registration Number
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="form-label-group">
                  <input
                    type="text"
                    id={this.idCompanyWebsite}
                    className="form-control"
                    placeholder="Company Website"
                    required="required"
                  />
                  <label
                    htmlFor={this.idCompanyWebsite}
                    style={{ cursor: "text" }}
                  >
                    Company Website
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-12">
                    <div className="form-label-group">
                      <textarea
                        id={this.idCompanyDescription}
                        rows="4"
                        className="form-control"
                        placeholder="A word about your company!"
                        required="required"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-8">
                    <div className="form-label-group">
                      <input
                        ref={
                          (this.state.errorStatus === 215 ||
                            this.state.errorStatus === 216) &&
                          this.state.showError
                            ? this.textInput
                            : null
                        }
                        type="text"
                        onChange={this.onInputTextChangeHandler}
                        id={this.idUserName}
                        className={
                          (this.state.errorStatus === 215 ||
                            this.state.errorStatus === 216) &&
                          this.state.showError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Contact Person Name"
                        required="required"
                      />
                      <div className="invalid-feedback">
                        {this.state.errorMessage}
                      </div>
                      <label
                        htmlFor={this.idUserName}
                        style={{ cursor: "text" }}
                      >
                        Contact Person Name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-label-group">
                      <div className="dropdown">
                        <button
                          id="idtypeButton"
                          className="btn btn-primary dropdown-toggle h-100"
                          type="button"
                          style={{
                            minHeight: "43px",
                            float: "right",
                            minWidth: "100px"
                          }}
                          data-toggle="dropdown"
                        >
                          Select Designation
                          <span className="caret" />
                        </button>
                        <ul className="dropdown-menu">
                          <li
                            className="px-2 pb-2 border-bottom"
                            style={{ cursor: "pointer" }}
                            onClick={this.setDesignation.bind(
                              this,
                              "Chief Executive Officer"
                            )}
                          >
                            <a>Chief Executive Officer</a>
                          </li>
                          <li
                            className="px-2 p-2 border-bottom"
                            style={{ cursor: "pointer" }}
                            onClick={this.setDesignation.bind(
                              this,
                              "Chief Operating Officer"
                            )}
                          >
                            <a>Chief Operating Officer</a>
                          </li>
                          <li
                            className=" px-2 pt-2"
                            onClick={this.setDesignation.bind(
                              this,
                              "Human Resource"
                            )}
                          >
                            <a style={{ cursor: "pointer" }}>Human Resource</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="form-label-group">
                  <input
                    type="email"
                    ref={
                      (this.state.errorStatus === 217 ||
                        this.state.errorStatus === 218 ||
                        this.state.errorStatus === 222) &&
                      this.state.showError
                        ? this.textInput
                        : null
                    }
                    id={this.idUserEmailID}
                    onChange={this.onInputTextChangeHandler}
                    className={
                      (this.state.errorStatus === 217 ||
                        this.state.errorStatus === 218 ||
                        this.state.errorStatus === 222) &&
                      this.state.showError
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Contact Person Email Address"
                    required="required"
                  />
                  <div className="invalid-feedback">
                    {this.state.errorMessage}
                  </div>
                  <label
                    htmlFor={this.idUserEmailID}
                    style={{ cursor: "text" }}
                  >
                    Contact Person Email Address
                  </label>
                </div>
              </div>

              <div className="form-group">
                <div className="form-label-group">
                  <input
                    type="text"
                    ref={
                      this.state.errorStatus === 227 && this.state.showError
                        ? this.textInput
                        : null
                    }
                    id={this.idUserPhoneNumber}
                    className={
                      this.state.errorStatus === 227 && this.state.showError
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Contact Person Phone Number"
                    required="required"
                  />
                  <div className="invalid-feedback">
                    {this.state.errorMessage}
                  </div>
                  <label
                    htmlFor={this.idUserPhoneNumber}
                    style={{ cursor: "text" }}
                  >
                    Contact Person Phone Number
                  </label>
                </div>
              </div>

              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-label-group">
                      <input
                        ref={
                          (this.state.errorStatus === 219 ||
                            this.state.errorStatus === 220) &&
                          this.state.showError
                            ? this.textInput
                            : null
                        }
                        type="password"
                        onChange={this.onInputTextChangeHandler}
                        id={this.idPassword}
                        className={
                          (this.state.errorStatus === 219 ||
                            this.state.errorStatus === 220) &&
                          this.state.showError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Password"
                        required="required"
                      />
                      <div className="invalid-feedback">
                        {this.state.errorMessage}
                      </div>
                      <label
                        htmlFor={this.idPassword}
                        style={{ cursor: "text" }}
                      >
                        Password
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-label-group">
                      <input
                        ref={
                          this.state.errorStatus === 219.1 &&
                          this.state.showError
                            ? this.textInput
                            : null
                        }
                        type="password"
                        id={this.idRePassword}
                        className={
                          this.state.errorStatus === 219.1 &&
                          this.state.showError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Confirm password"
                        required="required"
                      />
                      <div className="invalid-feedback">
                        {this.state.errorMessage}
                      </div>
                      <label
                        htmlFor={this.idRePassword}
                        style={{ cursor: "text" }}
                      >
                        Confirm password
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="input-group mb-3">
                    <div className="custom-file">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={() =>
                          this.displaySelectedFIleName(
                            this.idCompanyLogo,
                            this.idCompanyLogoLabel,
                            "Select Company's Photo/Logo"
                          )
                        }
                        className="custom-file-input"
                        id={this.idCompanyLogo}
                        style={{ cursor: "pointer" }}
                      />

                      <label
                        id={this.idCompanyLogoLabel}
                        className="custom-file-label"
                        htmlFor="inputGroupFile01"
                      >
                        Select Company's Photo/Logo
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <a
                className="btn btn-primary btn-block float-left"
                style={{ color: "white" }}
                onClick={this.performSignup}
              >
                Register
              </a>
            </form>
          </div>
          <hr className="mt-0" />
          <div className="text-center mb-3">
            <a className="d-block small" href="/login">
              Already have an account? Signin now!
            </a>
            <a className="d-block small" href="/forgot-password">
              Forgot Password?
            </a>
          </div>
        </div>

        {this.state.isloading && (
          <LoadingModal
            show={this.state.isloading}
            message="Proceesing your data. Please wait..."
          />
        )}

        <MessageDialog
          show={this.state.showJumboDialog}
          title={this.state.jumboDialogTitle}
          message={this.state.jumboDialogMessage}
          hideJumboDialog={this.navigateToLoginPage}
          buttonName="Done"
        />
      </div>
    );
  }
}

export default Signup;
