import React, { Component } from "react";
import { Collapse, Alert } from "reactstrap";
import LoadingModal from "../dialogs/LoadingModal";
import MessageDialog from "../dialogs/MessageDialog";
import RestAPIHelper from "../../RestAPIHelper";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { forgotPassword: "" };

    this.sendEmailLink = this.sendEmailLink.bind(this);
    this.navigateToLoginPage = this.navigateToLoginPage.bind(this);
  }

  navigateToLoginPage() {
    window.location.href = "/login";
    this.setState({
      showErrorMessage: false
    });
  }

  async sendEmailLink() {
    this.setState({
      isloading: true,
      showErrorMessage: false,
      showMessageDialog: false,
      messageDialogTitle: null,
      messageDialogMessage: null,
      showError: false,
      errorStatus: null
    });
    const url = this.props.baseURL + "forgot/password";
    const emailID = document.getElementById("userEmailAddress").value;

    if (!emailID) {
      this.setState({
        isloading: false,
        showMessageDialog: false,
        messageDialogTitle: null,
        messageDialogMessage: null,
        showErrorMessage: false,
        showError: true,
        errorStatus: 201,
        errorMessage: "Please enter your registered account email address"
      });
    } else {
      let parameters = {
        userEmailID: emailID
      };

      let apiHelper = new RestAPIHelper();
      const apiResponse = await apiHelper.post(url, parameters);

      if (apiResponse["status"] == 200) {
        this.setState({
          isloading: false,
          showMessageDialog: true,
          messageDialogTitle: "Link has been sent to your Email Address",
          messageDialogMessage: apiResponse["message"]
        });
      } else {
        this.setState({
          isloading: false,
          showMessageDialog: false,
          messageDialogTitle: null,
          messageDialogMessage: null,
          showErrorMessage: true,
          errorMessage: apiResponse["message"]
        });
      }
    }
  }

  render() {
    return (
      <div className="container">
        <div align="center" className="col-md-12 col-sm-12">
          <img
            style={{ maxHeight: "100px", marginTop: "5%" }}
            src="img/logo.SVG"
          />
        </div>

        <div className="card card-register mx-auto mt-5">
          <div className="card-header">
            <h4>Reset Password</h4>
          </div>
          <Collapse isOpen={this.state.showErrorMessage}>
            <Alert color="danger" className="rounded-0 mb-0">
              {this.state.errorMessage}
            </Alert>
          </Collapse>
          <div className="card-body">
            <div className="text-center mb-4">
              <h4>Forgot your password?</h4>
              <p>
                Enter your email address and we will send you instructions on
                how to reset your password.
              </p>
            </div>
            <form>
              <div className="form-group">
                <div className="form-label-group">
                  <input
                    type="email"
                    id="userEmailAddress"
                    className={
                      this.state.errorStatus === 201 && this.state.showError
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Enter email address"
                    required="required"
                    autoFocus="autofocus"
                  />
                  <div className="invalid-feedback">
                    {this.state.errorMessage}
                  </div>
                  <label htmlFor="userEmailAddress" style={{ cursor: "text" }}>
                    Enter your registered email address
                  </label>
                </div>
              </div>
              <a
                className="btn btn-primary btn-block"
                href="#"
                onClick={this.sendEmailLink}
              >
                Reset Password
              </a>
            </form>
            <div className="text-center">
              <a className="d-block small mt-3" href="/signup">
                Register an Account
              </a>
              <a className="d-block small" href="/login">
                Login Page
              </a>
            </div>
          </div>
        </div>
        {this.state.isloading ? (
          <LoadingModal
            show={this.state.isloading}
            message="Waiting for server response. Please wait..."
          />
        ) : null}
        <MessageDialog
          show={this.state.showMessageDialog}
          title={this.state.messageDialogTitle}
          message={this.state.messageDialogMessage}
          hideJumboDialog={this.navigateToLoginPage}
          buttonName="Done"
        />
      </div>
    );
  }
}

export default ForgotPassword;
