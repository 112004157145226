import React, { Component } from "react";
import RestAPIHelper from "../../RestAPIHelper";
import SessionHelper from "../../SessionHelper";

class SuperAdminActivityLog extends Component {
  constructor(props) {
    super(props);
    this.state = { dataLoaded: false };
  }

  async componentDidMount() {
    let session = new SessionHelper();

    var url = this.props.baseURL + "users/activitylog";

    let parameters = {
      authenticationToken: session.get("authID")
    };

    let apiHelper = new RestAPIHelper();
    const apiResponse = await apiHelper.post(url, parameters);

    if (apiResponse["data"] === undefined) {
      this.setState({ dataLoaded: true });
    } else {
      let x = [];
      let counter = 0;
      for (var keys in apiResponse["data"]["Activity Log List"]) {
        if (!x[counter]) x[counter] = [];
        for (var data in apiResponse["data"]["Activity Log List"][keys]) {
          x[counter][data] =
            apiResponse["data"]["Activity Log List"][keys][data];
        }
        counter++;
      }

      this.setState({
        dataLoaded: true,
        status: apiResponse["status"],
        message: apiResponse["message"],
        activityLogData: x
      });
    }
  }

  render() {
    let negativeCreditsActionCodes = ["VIEW_INT_DET"];
    let positiveCreditsActionCodes = ["ADD_INTERVIEW"];
    let activityList;
    if (this.state.dataLoaded === true && this.state.activityLogData != null) {
      const activityLogTableData = Object.values(
        this.state.activityLogData
      ).map((data, index) => {
        let options = {
          day: "numeric",
          month: "2-digit",
          year: "numeric",
          hour: "numeric",
          minute: "numeric"
        };
        let today = new Date(data["PERFORMED_ON"]);
        return (
          <tr key={index}>
            <td>{data["USER_ID"]}</td>
            <td>{data["USER_NAME"]}</td>
            <td>
              {data["COMPANY_NAME"] ? (
                data["COMPANY_NAME"]
              ) : (
                <span className="small text-muted">-- Interviewee --</span>
              )}
            </td>
            <td>{data["DESCRIPTION"]}</td>
            <td>{data["SUBJECT_ID"] ? data["SUBJECT_ID"] : "---"}</td>
            <td>{today.toLocaleDateString("en-IN", options)}</td>
            <td>
              {negativeCreditsActionCodes.includes(data["ACTION_CODE"]) ? (
                <font color="red">
                  <b>-{data["CREDITS"]}</b>
                </font>
              ) : positiveCreditsActionCodes.includes(data["ACTION_CODE"]) ? (
                <font color="green">
                  <b>+{data["CREDITS"]}</b>
                </font>
              ) : (
                data["CREDITS"]
              )}
            </td>
            <td style={{ display: "none" }}>{data["PERFORMED_ON"]}</td>
          </tr>
        );
      });

      activityList = (
        <table
          className="table table-bordered mb-0"
          id="dataTable"
          width="100%"
          cellSpacing="0"
        >
          <thead>
            <tr>
              <th>User ID</th>
              <th>User Name</th>
              <th>Company Name</th>
              <th>Action</th>
              <th>Subject ID</th>
              <th>Performed On</th>
              <th>Credits</th>
              <th style={{ display: "none" }}>Task Performed On</th>
            </tr>
          </thead>
          <tbody>{activityLogTableData}</tbody>
        </table>
      );
    } else if (this.state.dataLoaded === false) {
      activityList = "Loading Data. Please wait...";
    }
    return (
      <div className="card mb-3">
        <div className="card-header">
          <h4>Activity Log</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">{activityList}</div>
        </div>
      </div>
    );
  }
}

export default SuperAdminActivityLog;
